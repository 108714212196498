import { ItemMapper } from 'src/app/lib/item-mapper';
import { ResourceModel } from 'src/app/models/resource.model';
import { FilterMenuModel } from 'src/app/models/filter-menu.model';
import { PedagogicalModel } from 'src/app/models/pedagogical.model';
import { ResourceDetailsModel } from 'src/app/models/resource-details.model';

export class ResourceMapper extends ItemMapper<ResourceModel> {
  protected mapFromItem(item): ResourceModel {
    const resourceModel = new ResourceModel();
    // resourceModel.pedagogicalModels = item.pedagogicalModels
    // resourceModel.resources = item.resources
    console.log(item);
    if (item['filterMenu'])
      for (var i = 0; i < item['filterMenu'].length; i++) {
        var filterMenuModel = new FilterMenuModel();
        filterMenuModel.ancestors = item.filterMenu[i].ancestors;
        filterMenuModel.createdAt = item.filterMenu[i].createdAt;
        filterMenuModel.identifier = item.filterMenu[i].identifier;
        filterMenuModel.relations = item.filterMenu[i].relations;
        filterMenuModel.standardIdentifierId =
          item.filterMenu[i].standardIdentifierId;
        if (item.filterMenu[i].title.length > 3) {
          filterMenuModel.name = item.filterMenu[i].title;
        } else {
          filterMenuModel.name =
            item.filterMenu[i].title + ' - ' + item.filterMenu[i].fullStatement;
        }
        // filterMenuModel.tmpName = item.filterMenu[i].fullStatement;
        filterMenuModel.type = item.filterMenu[i].type
          ? item.filterMenu[i].type
          : 'Topic';
        filterMenuModel.updatedAt = item.filterMenu[i].updatedAt;
        filterMenuModel.uri = item.filterMenu[i].uri;
        filterMenuModel.version = item.filterMenu[i].__v;
        filterMenuModel.id = item.filterMenu[i]._id;
        // filterMenuModel.fullStatement = item.filterMenu[i].fullStatement;

        resourceModel.filterMenu.push(filterMenuModel);
      }
    for (var j = 0; j < item['pedagogicalModels'].length; j++) {
      var pedagogicalModel = new PedagogicalModel();
      pedagogicalModel.elaborate = item.pedagogicalModels[j].elaborate;
      pedagogicalModel.engage = item.pedagogicalModels[j].engage;
      pedagogicalModel.evaluate = item.pedagogicalModels[j].evaluate;
      pedagogicalModel.explain = item.pedagogicalModels[j].explain;
      pedagogicalModel.explore = item.pedagogicalModels[j].explore;

      resourceModel.pedagogicalModels.push(pedagogicalModel);
    }

    for (var k = 0; k < item['resources'].length; k++) {
      var resourceDetailsModel = new ResourceDetailsModel();
      resourceDetailsModel.accessMode = item.resources[k].accessMode;
      resourceDetailsModel.accessibilityHazards =
        item.resources[k].accessibilityHazards;
      resourceDetailsModel.aerGUID = item.resources[k].aerGUID;
      resourceDetailsModel.aerReferenceID = item.resources[k].aerReferenceID;
      resourceDetailsModel.author = item.resources[k].author;
      resourceDetailsModel.createdAt = item.resources[k].createdAt;
      resourceDetailsModel.description = item.resources[k].description;
      resourceDetailsModel.educationalAudience =
        item.resources[k].educationalAudience;
      // resourceDetailsModel.language = item.resources[k].language
      resourceDetailsModel.language = this.mutatedLanguage(
        item.resources[k].language
      );
      resourceDetailsModel.languageRegiondesignator =
        item.resources[k].languageRegiondesignator;
      resourceDetailsModel.learningObjectives =
        item.resources[k].learningObjectives;
      resourceDetailsModel.learningResourceType =
        item.resources[k].learningResourceType;
      resourceDetailsModel.name = item.resources[k].name;
      resourceDetailsModel.pedagogicalModels =
        item.resources[k].pedagogicalModels;
      resourceDetailsModel.publisher = item.resources[k].publisher;
      resourceDetailsModel.related = item.resources[k].related;
      resourceDetailsModel.standardNodesInfo =
        item.resources[k].standardNodesInfo;
      resourceDetailsModel.subject = item.resources[k].subject;
      resourceDetailsModel.targetFileHeight =
        item.resources[k].targetFileHeight;
      resourceDetailsModel.targetFileThumbnailURL =
        item.resources[k].targetFileThumbnailURL;
      resourceDetailsModel.targetFileURL = item.resources[k].targetFileURL;
      resourceDetailsModel.targetFileWidth = item.resources[k].targetFileWidth;
      resourceDetailsModel.technicalFormat = item.resources[k].technicalFormat;
      resourceDetailsModel.textComplexity = item.resources[k].textComplexity;
      resourceDetailsModel.timeRequired = item.resources[k].timeRequired;
      resourceDetailsModel.transcriptURL = item.resources[k].transcriptURL;
      resourceDetailsModel.age = item.resources[k].typicalAgeRange;
      resourceDetailsModel.updatedAt = item.resources[k].updatedAt;
      resourceDetailsModel.version = item.resources[k].__v;
      resourceDetailsModel.id = item.resources[k]._id;

      resourceModel.resources.push(resourceDetailsModel);
      console.log(resourceModel.resources);
    }

    resourceModel.totalCount = item.totalcount;

    resourceModel.schoolData = item.schoolData;

    console.log(resourceModel);

    return resourceModel;
  }

  mutatedLanguage(originalLanguageList) {
    enum languageList {
      'en' = 'English',
      'ch' = 'Chinese',
    }

    for (var i = 0; i < originalLanguageList.length; i++) {
      var temp = originalLanguageList[i];
      originalLanguageList[i] = languageList[originalLanguageList[i]];
    }
    return originalLanguageList;
  }
}
