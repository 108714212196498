import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {RelatedCardStore} from './related-card.store'
import { Router } from '@angular/router';
import { GetResourceDetailsUseCase } from 'src/app/dashboard/business-logic/use-cases/get-resource-details.use-case';

@Component({
    selector: 'app-related-card',
    templateUrl: './related-card.component.html',
    styleUrls: ['./related-card.component.css']
})
export class RelatedCardComponent implements OnInit {
  router: any;
    constructor(private store: RelatedCardStore) {}
  ngOnInit(): void {
    console.log('insideRelatedAERs', this.resourceDetailsData);
  }

    @Input() resourceDetailsData: any
    @Output() relatedOutPut = new EventEmitter

  relatedCardDetails(relatedID) {
    this.relatedOutPut.emit(relatedID);
    console.log(relatedID);
    console.log("Related Card Details");
  }
}
