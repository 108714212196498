<div class="wrapper" *ngIf="resourceDetailsData.length > 0">
    <app-text class="h3-strong text-c300">Related</app-text>
    <div class="d-flex">
        <div class="related-card d-flex flex-column align-items-center" *ngFor="let item of resourceDetailsData; let first=first" (click)="relatedCardDetails(item._id)" [ngStyle]="{'margin-left' :  first ? '0px' : '25px'}">
            <app-image [src]="item.targetFileThumbnailURL" [class]="'related-image'" [width]="'325px'" [height]="'183px'"></app-image>
            <div class="desc d-flex flex-column">
                <app-text class="h4-strong text-b100" style="margin-top: 16px;">{{item.name}}</app-text>
                <app-text class="light-body-m text-c100">{{item.description.length>150 ? item.description.slice(0,150)+ '...' : item.description}}</app-text>
            </div>
        </div>
    </div>
</div>