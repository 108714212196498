<!-- <button (click)="copy(keywordForShow)">Copy</button> -->
<!-- <block-ui>
</block-ui> -->
<header class="top-sticky-bar">
  <nav
    class="top-filter d-flex justify-content-between align-items-center bg-s0"
  >
    <app-nav-filter
      (navFilterOutput)="selectedNavFilter($event)"
      [itemList]="_smartRefreshService.mutatedPedagogicalList"
      [selecteditem]="_smartRefreshService.selectedPedagogicalModel"
      [viewChange]="viewChange"
    ></app-nav-filter>
    <!-- <app-profile [viewChange]="viewChange" style="width: 80px;z-index: 100;"></app-profile> -->
  </nav>
  <div class="d-flex align-items-center justify-content-center search">
    <app-search
      [keyword]="
        _smartRefreshService.keyword ? _smartRefreshService.keyword : ''
      "
      [hint]="'Search resources...'"
      (searchOutput)="getSearchKeyword($event)"
    ></app-search>
  </div>
</header>
<article class="main-panel d-flex">
  <section class="left-panel">
    <div
      class="scroll-panel d-flex align-items-center flex-column"
      id="scroll-panel"
    >
      <app-select
        [boxWidth]="'286px'"
        [selectHeader]="'Education Standard'"
        [type]="'type1'"
        [headerClass]="'h5-strong text-c300'"
        style="margin-bottom: 16px;"
        [itemList]="_smartRefreshService.eduStandardList"
        (selectOutput)="selectedEduStandard($event)"
        *ngIf="_smartRefreshService.keyword !== null"
        [defaultSelected]="_smartRefreshService.defaultEducationStandard"
      ></app-select>

      <app-category
        [categoryList]="_smartRefreshService.categoryList"
        [defaultSelectedCategoryList]="_smartRefreshService.defaultCategoryList"
        (categoryOutput)="selectedgrade($event)"
      ></app-category>

      <app-select
        [selectHeader]="'Subject'"
        [type]="'type2'"
        [itemList]="_smartRefreshService.subjectList"
        [headerClass]="'h5-strong text-c100'"
        (selectOutput)="selectedSubject($event)"
      ></app-select>

      <app-select
        [selectHeader]="'Material types'"
        [type]="'type2'"
        [itemList]="_smartRefreshService.materialList"
        [headerClass]="'h5-strong text-c100'"
        (selectOutput)="selectedMaterial($event)"
      ></app-select>

      <app-select
        [selectHeader]="'Language'"
        [type]="'type2'"
        [itemList]="_smartRefreshService.languageList"
        [headerClass]="'h5-strong text-c100'"
        (selectOutput)="selectedLanguage($event)"
        id="test"
      ></app-select>

      <nav style="position: fixed; bottom: 0; width: 320px;" id="bottom-panel">
        <div class="about-us">
          <app-text class="body-m text-c300">About Us</app-text>
        </div>

        <div class="about-us">
          <app-text class="body-m text-c300">Help</app-text>
        </div>
        <div class="d-flex justify-content-center" style="padding: 16px;">
          <div class="power-by d-flex align-items-center">
            <app-text style="padding-right: 8px;" class="body-m text-c100">
              Powered by
            </app-text>
            <app-image
              [src]="'./assets/images/Amdon.png'"
              [maxWidth]="'96px'"
            ></app-image>
          </div>
        </div>
      </nav>
    </div>
  </section>

  <section class="right-panel">
    <div
      class="resource-list bg-s10"
      [ngStyle]="{ maxWidth: viewChange ? '448px' : '100%' }"
      id="resource-list"
    >
      <div class="d-flex justify-content-between" style="margin-bottom: 12px;">
        <div
          *ngIf="
            _smartRefreshService.keyword != '' &&
            _smartRefreshService.keyword != null &&
            _smartRefreshService.keyword != 'null'
          "
        >
          <app-text class="light-body-m text-c100">Search results:</app-text>
          <app-text class="h5-strong text-c300" style="margin-left: 4px;">
            {{ _smartRefreshService.keyword }} ({{ resourceModel.totalCount }})
          </app-text>
        </div>
        <div
          *ngIf="
            _smartRefreshService.keyword == '' ||
            _smartRefreshService.keyword == null ||
            _smartRefreshService.keyword == 'null'
          "
        >
          <app-text class="h5-strong text-c300">Latest Resources</app-text>
        </div>
        <div
          *ngIf="
            _smartRefreshService.keyword != '' &&
            _smartRefreshService.keyword != null &&
            _smartRefreshService.keyword != 'null'
          "
        >
          <app-order-list
            [showOrderList]="showOrderList"
            [orderList]="_smartRefreshService.orderList"
            [orderForShow]="_smartRefreshService.sortForShow"
            (orderOutput)="selectedOrder($event)"
          ></app-order-list>
        </div>
      </div>
      <div class="filter-view d-flex row m-0 p-0">
        <app-text
          *ngFor="let item of _smartRefreshService.filterList"
          style="margin: 0px 16px 8px 0px;"
        >
          <app-tag
            [type]="'type2'"
            (tagOutput)="removeFilterTag($event)"
            [tagName]="item"
          ></app-tag>
        </app-text>
      </div>
      <div *ngIf="!isloading">
        <app-search-result
          [resource]="resourceDetailsModel"
          [viewChange]="viewChange"
          (outputForDetail)="goToDetail($event)"
          (buttonOutput)="addToCanvas($event)"
          [keyword]="_smartRefreshService.keyword"
        ></app-search-result>
        <div
          class="d-flex justify-content-center"
          style="margin-top: 24px; margin-bottom: 57px; min-height: 121px;"
        >
          <app-button
            *ngIf="
              resourceModel.totalCount > limit &&
              resourceModel.totalCount > skip
            "
            [class]="'button-secondary'"
            [width]="'480px'"
            [height]="'40px'"
            (click)="loadMore()"
            [disabled]="btnLoading"
          >
            {{ loadMoreText }}
          </app-button>
        </div>
        <app-no-results-found
          *ngIf="isSearch && resourceModel.totalCount == 0"
        ></app-no-results-found>
      </div>
      <div *ngIf="isloading">
        <div *ngFor="let tmp of tempList">
          <app-home-page-loading></app-home-page-loading>
        </div>
      </div>
    </div>
  </section>
</article>
