<main class="search-result-wrap" *ngFor="let item of resource">
  <div style="min-height: 152px" [ngClass]="{ 'd-flex': !viewChange }">
    <div
      class="poster"
      style="margin-right: 12px"
      [ngStyle]="{
        width: viewChange ? '416px' : '220px',
        'border-radius': viewChange ? '4px' : '0px'
      }"
      [ngClass]="{
        'd-flex': viewChange,
        'justify-content-center': viewChange,
        'align-items-center': viewChange,
        'bg-s40': viewChange
      }"
    >
      <app-image
        [src]="item.targetFileThumbnailURL"
        [width]="'220px'"
        [height]="'140px'"
        [maxWidth]="'220px'"
        [class]="borderClass"
      >
      </app-image>
    </div>
    <div
      class="desc"
      [ngStyle]="{
        width: viewChange ? '416px' : '320px',
        height: viewChange ? 'auto' : '152px',
        'margin-right': viewChange ? '0px' : '24px',
        'margin-top': viewChange ? '0px' : '12px'
      }"
    >
      <div class="d-flex flex-column">
        <app-text
          class="ht-strong text-b100 highlight cursor"
          (click)="clickedName(item)"
          [innerHTML]="item.name | highlight: keyword"
        ></app-text>
        <app-text
          class="small text-c100 highlight"
          [ngStyle]="{ 'margin-top': viewChange ? '0px' : '4px' }"
          [innerHTML]="item.description | highlight: keyword"
        ></app-text>
      </div>
      <app-text class="d-flex" style="margin-top: 4px">
        <app-tag
          [type]="'type1'"
          style="margin-right: 8px"
          [tagName]="tag"
          *ngFor="let tag of item.pedagogicalModels"
        >
        </app-tag>
      </app-text>
    </div>
    <div
      class="d-flex"
      [ngStyle]="{ 'margin-bottom': viewChange ? '16px' : '0px' }"
    >
      <div
        class="type-value"
        [ngStyle]="{
          'margin-top': viewChange ? '0px' : '16px',
          'margin-right': viewChange ? '0px' : '25px'
        }"
      >
        <app-type-value-table
          [resource]="item"
          [ngStyle]="{ width: viewChange ? 'auto' : '152px' }"
        ></app-type-value-table>
      </div>
      <div class="provider-group d-flex flex-column">
        <app-image
          [src]="'./assets/images/Amdon.png'"
          [width]="'96px'"
          [height]="'30px'"
          [maxWidth]="'96px'"
          style="margin-bottom: 16px"
        ></app-image>
        <app-image
          [src]="'./assets/images/image 1.png'"
          [width]="'96px'"
          [height]="'64.13px'"
          [maxWidth]="'96px'"
        ></app-image>
      </div>
    </div>
  </div>
  <a id="{{ item.id }}">
    <app-button [width]="'165px'" [height]="'36px'" (click)="addToCanvas(item)">
      <app-image [src]="'./assets/icons/LMS.svg'" style="padding-right: 10px">
      </app-image>
      <app-text>Add to LMS</app-text>
    </app-button>
  </a>
</main>
