import { Inject, Injectable } from '@angular/core';
import {
  DataRequirements,
  DataRequirementsInjectionToken,
} from '../data.requirements';
import { ResourceMapper } from '../mappers/resource.mapper';

@Injectable({
  providedIn: 'root',
})
export class SearchResourceUseCase {
  constructor(
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements,
    private mapper: ResourceMapper
  ) {}

  run(
    identifier,
    keyword,
    subject,
    materialTypes,
    language,
    pedagogicalModels,
    limit,
    skip,
    sortDirection,
    platformId
  ) {
    console.log(limit, skip);

    var subjectList = [];
    if (subject.length > 0)
      subjectList = this.mutatedList(subject, subjectList);

    var materialTypesList = [];
    if (materialTypes.length > 0)
      materialTypesList = this.mutatedList(materialTypes, materialTypesList);

    var languageList = [];
    if (language.length > 0)
      languageList = this.mutatedList(language, languageList);
    console.log('languageList', languageList);

    // var pedagogicalModelsList = [];
    // console.log(pedagogicalModels)
    // if(pedagogicalModels.length > 0)
    // pedagogicalModelsList = this.mutatedList(pedagogicalModels,pedagogicalModelsList)

    return this.data
      .searchResource(
        identifier,
        keyword,
        subjectList,
        materialTypesList,
        languageList,
        pedagogicalModels,
        limit,
        skip,
        sortDirection,
        platformId
      )
      .pipe(this.mapper.map);
  }

  mapLanguageWithEnum(name) {
    enum languageList {
      'English' = 'en',
      'Chinese' = 'ch',
    }
    console.log(languageList[name]);
    return languageList[name];
  }

  mutatedList(originalList, mutatedList) {
    console.log(originalList, mutatedList);
    mutatedList = [];
    for (var i = 0; i < originalList.length; i++) {
      mutatedList.push(originalList[i].name);
    }
    console.log(mutatedList);
    return mutatedList;
  }
}
