import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { SelectStore } from './select.store';
import * as $ from 'jquery';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectComponent implements OnInit {
  @ViewChild('#selectBox1') selectBox1: ElementRef;
  @ViewChild('#selectBox2') selectBox2: ElementRef;
  @Input() boxWidth = '320px';
  @Input() boxHeight = null;
  @Input() selectHeader;
  @Input() type;
  @Input() default = false;
  @Input() itemList = [];
  @Input() headerClass = null;
  @Input() defaultSelected = null;

  @Output() selectOutput = new EventEmitter();

  public arrowDown = './././assets/icons/search_l.svg';
  public showItemList = false;
  public selectedName = null;
  public previousName = null;

  constructor(private store: SelectStore, private elementRef: ElementRef) {}
  ngOnInit(): void {
    console.log(this.itemList);
    console.log(this.itemList.length);
    console.log('Select', this.defaultSelected);
    if (this.defaultSelected) {
      this.selectedName = this.defaultSelected;
    }
  }

  @HostListener('document:click', ['$event'])
  outside(event) {
    console.log(event.target.parentElement, 'event.target.id');
    // console.log(this.selectBox1, 'this.selectBox1.nativeElement');
    // console.log(this.selectBox2, 'this.selectBox2.nativeElement');
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.showItemList = false;
    }
  }

  clickedSelect() {
    setTimeout(() => {
      this.changePosition();
    }, 50);
    if (this.itemList.length > 0) this.showItemList = !this.showItemList;
  }

  changeItemSelection(position) {
    this.selectedItem(this.itemList[position], false);
  }

  selectedItem(item, emitOutputEvent: boolean) {
    console.log(this.itemList);
    if (this.type == 'type1') {
      this.selectedName = item.name ? item.name : item.title;
      if (this.previousName !== this.selectedName && emitOutputEvent)
        this.selectOutput.emit(item);
    } else {
      this.selectOutput.emit(item);
    }
  }

  changePosition() {
    if (
      document.getElementById('height-Sub') &&
      document.getElementById('height-Mat') &&
      document.getElementById('height-Lan')
    ) {
      var heightOfScroll =
        document.getElementById('height-Sub').offsetHeight +
        document.getElementById('height-Mat').offsetHeight +
        document.getElementById('height-Lan').offsetHeight;
      if (document.getElementById('height-cat'))
        heightOfScroll += document.getElementById('height-cat').offsetHeight;

      var windowHeight = window.innerHeight;
      var aboutUsHeight = 176;
      console.log(heightOfScroll, windowHeight, aboutUsHeight, '70');
      if (heightOfScroll > windowHeight - aboutUsHeight - 100)
        document.getElementById('bottom-panel').style.position = 'relative';
      else document.getElementById('bottom-panel').style.position = 'fixed';
    }
  }

  getSelectHeader() {
    if (this.itemList.length == 0) {
      return this.selectHeader;
    } else if (this.selectedName === null && this.itemList.length != 0) {
      var defaultItem = this.itemList[0].name
        ? this.itemList[0].name
        : this.itemList[0].title;
      if (this.default) {
        return defaultItem.length > 25
          ? defaultItem.slice(0, 25) + '...'
          : defaultItem;
      } else {
        return this.selectHeader;
      }
    } else {
      return this.selectedName.length > 25
        ? this.selectedName.slice(0, 25) + '...'
        : this.selectedName;
    }
  }
}
