import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TranscriptStore {

}

export enum TranscriptStoreKeys {

}
