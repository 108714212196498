<div class="detail-frame">
  <div class="d-flex" style="margin-bottom: 8px;">
    <app-text
      *ngFor="let tag of resourceDetailsData['pedagogicalModels']"
      style="padding-right: 8px;"
    >
      <app-tag [tagName]="tag" [type]="'type1'" [class]="'tag1-1'"></app-tag>
    </app-text>
  </div>
  <app-text [class]="'body-l'">
    {{ resourceDetailsData['description'] }}
  </app-text>
  <div class="d-flex flex-column" style="padding-top: 16px;">
    <div class="row">
      <div class="col-md-4 light-body-l">
        <app-text>Subject</app-text>
      </div>
      <div class="col-md-8 body-l text-g300">
        <app-text *ngFor="let subject of resourceDetailsData['subject']">
          {{ subject }}
        </app-text>
      </div>
      <div class="col-md-4 light-body-l">
        <app-text>Material types</app-text>
      </div>
      <div class="col-md-8 body-l text-g300">
        <app-text
          *ngFor="let data of resourceDetailsData['learningResourceType']"
        >
          {{ data }}
        </app-text>
      </div>
      <div class="col-md-4 light-body-l">
        <app-text>Age</app-text>
      </div>
      <div class="col-md-8 body-l text-g300">
        <app-text>{{ resourceDetailsData.age }}</app-text>
      </div>
      <div class="col-md-4 light-body-l">
        <app-text>Text complexity</app-text>
      </div>
      <div class="col-md-8 body-l text-g300">
        <app-text>{{ resourceDetailsData['testComplexity'] }}</app-text>
      </div>
      <div class="col-md-4 light-body-l">
        <app-text>language</app-text>
      </div>
      <div class="col-md-8 body-l text-g300">
        <app-text>{{ resourceDetailsData.language }}</app-text>
      </div>
      <div class="col-md-4 light-body-l">
        <app-text>Publisher</app-text>
      </div>
      <div class="col-md-8 body-l text-g300">
        <app-text>{{ resourceDetailsData['publisher'] }}</app-text>
      </div>
    </div>
  </div>
</div>
