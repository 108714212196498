<div [class]="class" *ngIf="!multiline">
  <div class="d-flex">
    <input
      #single
      [formControl]="control"
      [placeholder]="hint"
      *ngIf="!multiline"
      [type]="type"
      [readonly]="readonly"
      [ngStyle]="{
        width: width ? width : 'auto',
        height: height ? height : 'auto',
        'text-align': textAlign ? textAlign : 'left'
      }"
      [class]="class"
      id="search"
      oncommit="search()"
    />
    <ng-content></ng-content>
  </div>
</div>
<div *ngIf="multiline">
  <textarea
    #multi
    [formControl]="control"
    [rows]="rows"
    *ngIf="multiline"
    [placeholder]="hint"
    [readonly]="readonly"
    [ngStyle]="{ width: width ? width : 'auto', height: height }"
  ></textarea>
</div>
