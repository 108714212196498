<!-- <app-play-oam *ngIf="showPopup" (closePopup)="closePopupFunciton()" class="popup-wrapper" [oamUrl]="oamUrl" [fileType]="fileType"></app-play-oam> -->
<div class="main-wrapper">
  <div class="header-content">
    <div style="position: absolute; margin: 24px 0px 0px 24px;">
      <app-button
        [class]="'back-button'"
        [width]="'84px'"
        [height]="'40px'"
        (click)="backToDashboard()"
      >
        <app-image
          [src]="'./assets/icons/back-arrow.svg'"
          [width]="'8px'"
          [height]="'14px'"
        ></app-image>
        <app-text [class]="'text-b100 body-m'" style="padding-left: 8px;">
          Back
        </app-text>
      </app-button>
    </div>
    <div
      *ngIf="isLanguageChange == true"
      class="language-success d-flex justify-content-center"
      [style.height.px]="iframeHeight"
    >
      <div style="width: 1024px;">
        <div class="language-update d-flex">
          <div style="margin-left: 15px;">
            <app-image
              [src]="'./assets/icons/circle-tick.svg'"
              [width]="'24px'"
              [height]="'24px'"
            ></app-image>
          </div>
          <div>
            <span class="text-g100 body-m" style="padding-left: 8px;">
              OAM language was updated successfully.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="header-content d-flex justify-content-center">
      <!-- <app-image
        [src]="resourceDetailsData['targetFileThumbnailURL']"
        [width]="'850px'"
        [height]="'478px'"
        (click)="clickPlayBtn(resourceDetailsData)"
        class="preview-img"
      ></app-image> -->
      <div class="oamContent" [style.height.px]="iframeHeight">
        <iframe
          id="myFrame"
          class="oam-view"
          allowfullscree="true"
          [src]="
            safeUrl ? safeUrl : resourceDetailsData['targetFileThumbnailURL']
          "
        ></iframe>
      </div>
    </div>
  </div>
</div>
<div class="detail-main">
  <div class="detail-child">
    <div class="wrapper">
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center">
          <app-text class="h1-strong">
            {{ resourceDetailsData['name'] }}
          </app-text>
          <div class="toolTip-wrap" *ngIf="isShow">
            <span class="toolTip">Video</span>
            <span class="triangle-wrap">
              <span class="triangle"></span>
            </span>
          </div>
          <div
            class="image-indicator"
            (mouseenter)="hover()"
            (mouseleave)="notHover()"
            [ngClass]="{
              'image-indicator-hover':
                resourceDetailsData['technicalFormat'] !== 'HTML',
              cursor: resourceDetailsData['technicalFormat'] !== 'HTML'
            }"
          >
            <app-image
              *ngIf="resourceDetailsData['technicalFormat'] !== 'HTML'"
              [src]="'./assets/icons/video_rectangle.svg'"
              [width]="'24px'"
              [height]="'24px'"
              [maxWidth]="'24px'"
            ></app-image>
            <app-image
              *ngIf="resourceDetailsData['technicalFormat'] == 'HTML'"
              [src]="'./assets/icons/html5.svg'"
              [width]="'24px'"
              [height]="'24px'"
              [maxWidth]="'24px'"
            ></app-image>
          </div>
        </div>
        <div class="d-flex" style="margin-top: 24px;">
          <a id="{{ resourceID }}">
            <app-button
              [width]="'173px'"
              [height]="'40px'"
              style="margin-right: 16px;"
              (click)="addToCanvas()"
            >
              <app-image
                [src]="'./assets/icons/LMS.svg'"
                [width]="'24px'"
                [height]="'24px'"
                style="margin-right: 8px;"
              ></app-image>
              <app-text class="body-m">Add to LMS</app-text>
            </app-button>
          </a>
          <!-- <app-button
            [class]="'button-secondary'"
            [width]="'140px'"
            [height]="'40px'"
            (click)="clickedPermanentLink()"
          >
            Permanent Link
          </app-button> -->
          <!-- margin-left:32px -->
          <app-dropdown
            *ngIf="showLanguageChooser"
            [options]="languageOptions"
            [hint]="'English(US)'"
            style="margin-left: 0px;"
            (onOptionSelected)="onLanguageOptionChanged($event)"
          ></app-dropdown>
          <span class="copy-link-triangle" *ngIf="showPermanentLink"></span>
          <div class="permanent-link-wrapper" *ngIf="showPermanentLink">
            <div class="permanent-link">
              <span class="text-s0 body-m">
                {{
                  safeUrl
                    ? safeUrl.changingThisBreaksApplicationSecurity
                    : resourceDetailsData['targetFileThumbnailURL']
                }}
              </span>
            </div>
            <div class="d-flex align-items-center copy-link">
              <app-button
                [class]="'button-secondary'"
                [width]="'99px'"
                [height]="'36px'"
                (click)="
                  copy(
                    safeUrl
                      ? safeUrl.changingThisBreaksApplicationSecurity
                      : resourceDetailsData['targetFileThumbnailURL']
                  )
                "
              >
                Copy Link
              </app-button>
            </div>
          </div>
        </div>
      </div>
      <div class="image-container">
        <app-image
          [src]="'./assets/icons/Provider.svg'"
          [width]="'138px'"
          [height]="'38px'"
        ></app-image>
        <app-image
          [src]="'./assets/images/image 1.png'"
          [width]="'96px'"
          [height]="'64px'"
        ></app-image>
      </div>
    </div>
    <div
      class="justify-content-start align-items-center desc-trans d-flex"
      style="margin-top: 16px;"
    >
      <div
        class="description d-flex flex-column justify-content-center cursor"
        (click)="toggleDisplayDetails()"
      >
        <app-text class="body-l text-c300">Description</app-text>
        <div class="tab-bottom-bar" *ngIf="isShowDetails"></div>
      </div>
      <div
        class="transcript d-flex flex-column justify-content-center cursor"
        (click)="toggleDisplayTranscript()"
      >
        <app-text class="body-l text-c300">Transcript</app-text>
        <div class="tab-bottom-bar" *ngIf="isShowTranscript"></div>
      </div>
    </div>
    <div class="detail-child d-flex">
      <div class="left-panel">
        <app-transcript
          *ngIf="isShowTranscript"
          [resourceDetailsData]="resourceDetailsData"
        ></app-transcript>
        <app-detail
          *ngIf="isShowDetails"
          [resourceDetailsData]="resourceDetailsData"
        ></app-detail>
      </div>
      <div class="right-panel">
        <app-standard
          [resourceDetailsData]="resourceDetailsData['standardNodesInfo']"
          *ngIf="isShowApiData"
        ></app-standard>
      </div>
    </div>
  </div>
</div>
<hr style="border-top: 1px solid #e8e9eb; margin-top: 32px;" />
<div class="related-wrapper">
  <app-related-card
    [resourceDetailsData]="resourceDetailsData['related']"
    *ngIf="isShowApiData"
    (relatedOutPut)="goToDetail($event)"
  ></app-related-card>
</div>
