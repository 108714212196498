import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DetailStore {

}

export enum DetailStoreKeys {

}
