import {Component, Input, OnInit} from '@angular/core';
import {TypeValueTableStore} from './type-value-table.store'

@Component({
    selector: 'app-type-value-table',
    templateUrl: './type-value-table.component.html',
    styleUrls: ['./type-value-table.component.css']
})
export class TypeValueTableComponent implements OnInit{
    @Input() resource
    constructor(private store: TypeValueTableStore) {}
    
    ngOnInit(): void {
        // console.log(this.resource);
    }

    
}

export namespace Language
{
    export enum languageList
    {
        'en' = 'English',
        'cn' = 'Chinese'
    }
}
