import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SmartRefreshService {
  public orderList = [
    {
      name: 'Relevance',
      selected: false,
    },
    {
      name: 'Name asc',
      selected: false,
    },
    {
      name: 'Name desc',
      selected: false,
    },
    {
      name: 'Date asc',
      selected: false,
    },
    {
      name: 'Date desc',
      selected: true,
    },
  ];

  public platformId = null;
  public returnUrl = null;
  public source = null;
  public token = null;
  public apiResource = null;
  public apiResourceDetail = null;
  public previousDetailId = null;
  public keyword = '';
  public isBack = false;
  public sortDirection = 'updatedDateDESC';
  public sortForShow = '';

  public subjectList = [];
  public materialList = [];
  public languageList = [];
  public eduStandardList = [];
  public gradeList = [];
  public mutatedPedagogicalList = [];
  public categoryList = [];

  public filterList = [];
  public selectedSubjectList = [];
  public selectedMaterialList = [];
  public selectedLanguageList = [];
  public selectedPedagogicalModel = '';

  public defaultEducationStandard = null;
  public defaultCategoryList = [];

  constructor() {}

  updatePlatformId(platformId) {
    this.platformId = platformId;
  }

  updateReturnUrl(returnUrl) {
    this.returnUrl = returnUrl;
  }

  updateApiResource(apiResource) {
    this.apiResource = apiResource;
  }

  updateKeyword(keyword) {
    this.keyword = keyword;
  }

  updateIsBack(isBack) {
    this.isBack = isBack;
  }

  updateSubjectList(subjectList) {
    this.subjectList = subjectList;
  }

  updateLanguageList(languageList) {
    this.languageList = languageList;
  }

  updateMaterialList(materialList) {
    this.materialList = materialList;
  }

  updateEduStandardList(eduStandardList) {
    this.eduStandardList = eduStandardList;
  }

  updateGradeList(gradeList) {
    this.gradeList = gradeList;
  }

  updateMutatedPedagogicalList(mutatedPedagogicalList) {
    this.mutatedPedagogicalList = mutatedPedagogicalList;
  }

  updateCategoryList(categoryList) {
    this.categoryList = categoryList;
  }

  updateFilterList(filterList) {
    this.filterList = filterList;
  }

  updateSelectedSubjectList(selectedSubjectList) {
    this.selectedSubjectList = selectedSubjectList;
  }
  updateSelectedMaterialList(selectedMaterialList) {
    this.selectedMaterialList = selectedMaterialList;
  }
  updateSelectedLanguageList(selectedLanguageList) {
    this.selectedLanguageList = selectedLanguageList;
  }

  updateSelectedPedagogicalModel(selectedPedagogicalModel) {
    this.selectedPedagogicalModel = selectedPedagogicalModel;
  }

  updateDefaultEducationStandard(educationStandard) {
    this.defaultEducationStandard = educationStandard;
  }

  updateDefaultCategoryList(categoryList) {
    this.defaultCategoryList = categoryList;
  }

  updateSortDirection(sortDirection) {
    this.sortDirection = sortDirection;
  }

  updateSortForShow(sortForShow) {
    this.sortForShow = sortForShow;
  }

  updateOrderList(selectedItem) {
    console.log('order list', this.orderList, selectedItem);
    this.orderList.forEach((item) => {
      if (item.name !== selectedItem) item.selected = false;
      else item.selected = true;
    });
    console.log(this.orderList);
  }

  updateSource(source) {
    this.source = source;
  }

  updateToken(token) {
    this.token = token;
  }

  updateApiResourceDetail(apiResourceDetail) {
    this.apiResourceDetail = apiResourceDetail;
  }

  updatePreviousDetailId(previousDetailId) {
    this.previousDetailId = previousDetailId;
  }
}
