<!-- <select name="dropdown" id="dropdown">
  <option *ngFor="let option of options" [value]="option.value">{{option.label}}</option>
</select> -->
<div class="container">
  <div
    [style.width.px]="width" 
    class="toggle" 
    (click)="toggleDropdown($event)">
    <span
      class="header-text">{{selectedOption ? selectedOption.label : hint}}</span>
    <img
      [ngClass]="{'arrow': showDropdown}" 
      [src]="'/assets/icons/chevron_down_s.svg'"/>
  </div>
  <div
    [style.width.px]="width"
    [ngClass]="{'dropdown': true, 'show-dropdown': showDropdown}">
      <span
        class="dropdown-item" 
        *ngFor="let option of options"
        (click)="onOptionSelect($event, option)">{{option.label}}</span>
  </div>
</div>