import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface BusinessLogicRequirements {
  getAllSubjects(): Observable<any>;
  getAllMaterialTypes(): Observable<any>;
  getAllLanguages(): Observable<any>;
  getAllPedagogicalModels(): Observable<any>;
  getResourceDetails(resourceID): Observable<any>;
  searchResource(
    identifier,
    keyword,
    subject,
    materialTypes,
    language,
    pedagogicalModels,
    limit,
    skip,
    sortDirection,
    platformId
  ): Observable<any>;
  postUrl(aerUrl): Observable<any>;
}

export const BusinessRequirementsInjectionToken =
  new InjectionToken<BusinessLogicRequirements>(
    'dashboard Business Requirements'
  );
