import {
  Inject,
  Component,
  OnInit,
  HostListener,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../business-logic.requirements';
import { DashboardHomeStore } from './dashboard-home.store';
import { FilterMenuModel } from 'src/app/models/filter-menu.model';
import { ResourceModel } from 'src/app/models/resource.model';
import { PedagogicalModel } from 'src/app/models/pedagogical.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SmartRefreshService } from 'src/app/lib/smartRefresh.service';
enum Order {
  'Relevance' = 'relevance',
  'Name asc' = 'asc',
  'Name desc' = 'desc',
  'Date asc' = 'updatedDateASC',
  'Date desc' = 'updatedDateDESC',
}
@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home-controller.component.html',
  styleUrls: ['./dashboard-home-controller.component.css'],
})
export class DashboardHomeControllerComponent implements OnInit {
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: DashboardHomeStore,
    private router: Router,
    private _clipboardService: ClipboardService,
    private route: ActivatedRoute,
    public _smartRefreshService: SmartRefreshService
  ) {}

  @BlockUI() blockUI: NgBlockUI;

  public orderEnum = Order;

  public pedagogicalModelList = [];
  // public keyword =
  //   localStorage.getItem('searchWord') &&
  //   localStorage.getItem('searchWord') != 'null'
  //     ? localStorage.getItem('searchWord')
  //     : '';
  public keywordForShow = '';
  public identifier = '';
  public loadMoreText = 'Load more';
  public btnLoading = false;

  public filterMenuModel = new FilterMenuModel();
  public resourceModel = new ResourceModel();
  public pedagogicalModel = new PedagogicalModel();
  public resourceDetailsModel = [];

  public viewChange = false;
  public showOrderList = false;
  public limit = 5;
  public skip = 0;
  public isloading = true;

  public tempList = [1, 2, 3, 4];

  public loading = true;
  public version = '';
  public isSearch = false;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    console.log('Resize' + window.innerWidth);
    if (window.innerWidth <= 768) this.viewChange = true;
    else this.viewChange = false;
  }

  ngOnInit() {
    console.log(window.innerWidth);
    if (window.innerWidth <= 768) this.viewChange = true;
    console.log(this._smartRefreshService.sortDirection);
    console.log(
      'ngOnInit',
      this.orderEnum[this._smartRefreshService.sortDirection]
    );
    this.blockUI.start();
    if (this._smartRefreshService.isBack) {
      localStorage.removeItem('isBack');
      console.log('back true ', this._smartRefreshService.keyword);
      if (
        this._smartRefreshService.keyword != null &&
        this._smartRefreshService.keyword != 'null' &&
        this._smartRefreshService.keyword != ''
      ) {
        console.log('if');
        this.searchResourceByKeyword();
        localStorage.removeItem('searchWord');
      } else {
        console.log('else');
        localStorage.removeItem('searchWord');
        this.initialResources();
      }
    } else {
      this.route.queryParams.subscribe((params) => {
        this._smartRefreshService.updateReturnUrl(params['param']);
        this._smartRefreshService.updateSource(params['source']);
        this._smartRefreshService.updateToken(params['token']);
        this.version = params['version'];
        this._smartRefreshService.updatePlatformId(params['platformId']);
        // this._smartRefreshService.updatePlatformId(
        //   '03d835599a25b1669ed77f39aa9b5f57'
        // );
        // this.returnURL = 'http://canvas-temp.pagewerkz.com/courses/4/pages' //for test
        console.log(params);
        console.log('returnURL ', this._smartRefreshService.returnUrl);
        console.log('platformId ', this._smartRefreshService.platformId);
        localStorage.removeItem('searchWord');
        this.business.getAllMaterialTypes().subscribe(
          (data) => {
            this._smartRefreshService.materialList =
              this.mutatedListForSelect(data);
          },
          (err) => {
            console.log(err);
          }
        );
        this.initialResources();
      });
    }

    // this.keyword = 'light'
    // this.searchResourceByKeyword()
  }

  initialResources() {
    if (!this._smartRefreshService.isBack) {
      this._smartRefreshService.updateApiResource(null);
      this._smartRefreshService.updateEduStandardList([]);
      this._smartRefreshService.updateGradeList([]);
      this._smartRefreshService.updateCategoryList([]);
      this.identifier = '';
      this.skip = 0;
      this._smartRefreshService.updateSelectedLanguageList([]);
      this._smartRefreshService.updateSelectedSubjectList([]);
      // this._smartRefreshService.updateSelectedMaterialList([]);
      this._smartRefreshService.updateSelectedPedagogicalModel('');
      this._smartRefreshService.updateFilterList([]);
    } else {
      //updatecategoryList
      console.log('isBack but clear:', this._smartRefreshService.categoryList);
      console.log('isBack but clear:', this._smartRefreshService.filterList);
      console.log(
        'isBack but lang:',
        this._smartRefreshService.selectedLanguageList
      );
      console.log(
        'isBack but mat:',
        this._smartRefreshService.selectedMaterialList
      );
      console.log(
        'isBack but sub:',
        this._smartRefreshService.selectedSubjectList
      );
      console.log(
        'isBack but ped:',
        this._smartRefreshService.selectedPedagogicalModel
      );
    }

    console.log(
      'initialResources calling.....',
      this._smartRefreshService.platformId
    );
    this.business
      .searchResource(
        '',
        '',
        '',
        '',
        '',
        '',
        5,
        this.skip,
        this._smartRefreshService.sortDirection,
        this._smartRefreshService.platformId
      )
      .subscribe(
        (res) => {
          this.resourceModel = res[0];
          this.resourceDetailsModel = this.resourceModel.resources;
          if (!this._smartRefreshService.isBack) {
            this._smartRefreshService.subjectList = this.mutatedListForSelect(
              this.resourceModel.schoolData.subjects
            );

            enum languageList {
              'en' = 'English',
              'ch' = 'Chinese',
            }
            var tmpLanguages = this.resourceModel.schoolData.languages.map(
              (lang) => languageList[lang]
            );
            this._smartRefreshService.languageList =
              this.mutatedListForSelect(tmpLanguages);
            var eduStandardList = [];
            console.log(this.resourceDetailsModel);
            for (
              var i = 0;
              i < this.resourceModel.schoolData.identifiers.length;
              i++
            ) {
              eduStandardList.push(
                this.resourceModel.schoolData.identifiers[i]
              );
            }
            this._smartRefreshService.updateEduStandardList(eduStandardList);

            var mutatedPedagogicalList = [];
            for (var pModel of Object.keys(
              this.resourceModel.pedagogicalModels[0]
            )) {
              var pModelForName = pModel[0].toUpperCase() + pModel.slice(1);
              var obj = {
                name: pModelForName,
                count: this.resourceModel.pedagogicalModels[0][pModel],
              };
              mutatedPedagogicalList.push(obj);
            }
            this._smartRefreshService.updateMutatedPedagogicalList(
              mutatedPedagogicalList
            );
          }

          this.blockUI.stop();
          this.isloading = false;
        },
        (err) => {
          this.blockUI.stop();
          console.error(err);
          this.isloading = false;
        }
      );
  }

  selectedNavFilter(item) {
    this._smartRefreshService.updateApiResource(null);
    console.log('from controller', item);
    console.log(this._smartRefreshService.selectedPedagogicalModel, item.name);
    if (this._smartRefreshService.selectedPedagogicalModel !== item.name) {
      this._smartRefreshService.updateSelectedPedagogicalModel(item.name);
      console.log('loop');
    } else {
      this._smartRefreshService.updateSelectedPedagogicalModel('');
    }
    this.searchResourceByFilter();
  }

  removeFilter(index) {
    var tmpList = this._smartRefreshService.filterList;
    tmpList.splice(index, 1);
    console.log('updateList', tmpList);
    return tmpList;
  }

  selectedSubject(subject) {
    this._smartRefreshService.updateApiResource(null);
    if (subject.selected) {
      var typeObj = {
        name: subject.name,
        type: 'subject',
      };
      // this.selectedSubjectList.push(subject.name);
      this._smartRefreshService.updateSelectedSubjectList([
        ...this._smartRefreshService.selectedSubjectList,
        typeObj,
      ]);
      // this.filterList.push(subject.name);
      this._smartRefreshService.updateFilterList([
        ...this._smartRefreshService.filterList,
        typeObj,
      ]);
      console.log(this._smartRefreshService.filterList);
    } else {
      console.log('un select sub');
      var removeIndex = this._smartRefreshService.selectedSubjectList
        .map(function (item) {
          return item.name;
        })
        .indexOf(subject.name);
      console.log('remove sub index:', removeIndex);
      console.log(
        this._smartRefreshService.selectedSubjectList.slice(
          removeIndex,
          removeIndex + 1
        )
      );
      var tmpSubList = this._smartRefreshService.selectedSubjectList;
      tmpSubList.splice(removeIndex, 1);
      this._smartRefreshService.updateSelectedSubjectList(tmpSubList);
      var removeIndex1 = this._smartRefreshService.filterList
        .map(function (item) {
          return item.name;
        })
        .indexOf(subject.name);
      console.log(
        this._smartRefreshService.filterList,
        removeIndex1,
        subject.name
      );
      this._smartRefreshService.updateFilterList(
        this.removeFilter(removeIndex1)
      );
    }
    // if (this.keyword !== '')
    this.searchResourceByFilter();
  }

  selectedMaterial(material) {
    this._smartRefreshService.updateApiResource(null);
    if (material.selected) {
      var typeObj = {
        name: material.name,
        type: 'material',
      };
      // this.selectedMaterialList.push(material.name);
      this._smartRefreshService.updateSelectedMaterialList([
        ...this._smartRefreshService.selectedMaterialList,
        typeObj,
      ]);
      this._smartRefreshService.updateFilterList([
        ...this._smartRefreshService.filterList,
        typeObj,
      ]);
      // this.filterList.push(material.name);
    } else {
      var removeIndex = this._smartRefreshService.selectedMaterialList
        .map(function (item) {
          return item.name;
        })
        .indexOf(material.name);
      var tmpMatList = this._smartRefreshService.selectedMaterialList;
      tmpMatList.splice(removeIndex, 1);
      this._smartRefreshService.updateSelectedMaterialList(tmpMatList);
      var removeIndex1 = this._smartRefreshService.filterList
        .map(function (item) {
          return item.name;
        })
        .indexOf(material.name);
      this._smartRefreshService.updateFilterList(
        this.removeFilter(removeIndex1)
      );
    }
    console.log(this._smartRefreshService.selectedMaterialList);
    // if (this.keyword !== '')
    this.searchResourceByFilter();
  }

  selectedLanguage(language) {
    this._smartRefreshService.updateApiResource(null);
    if (language.selected) {
      var forSelected = {
        name: this.mapLanguageWithEnum(language.name),
        type: 'language',
      };
      var forFilter = {
        name: language.name,
        type: 'language',
      };
      // this.selectedLanguageList.push(this.mapLanguageWithEnum(language.name));
      this._smartRefreshService.updateSelectedLanguageList([
        ...this._smartRefreshService.selectedLanguageList,
        forSelected,
      ]);
      // this.filterList.push(language.name);
      this._smartRefreshService.updateFilterList([
        ...this._smartRefreshService.filterList,
        forFilter,
      ]);
    } else {
      console.log(
        'un select lang',
        language.name,
        this._smartRefreshService.languageList[language.name]
      );
      var forSelected = {
        name: this.mapLanguageWithEnum(language.name),
        type: 'language',
      };
      var removeIndex = this._smartRefreshService.selectedLanguageList
        .map(function (item) {
          return item.name;
        })
        .indexOf(forSelected.name);
      var tmpLangList = this._smartRefreshService.selectedLanguageList;
      tmpLangList.splice(removeIndex, 1);
      this._smartRefreshService.updateSelectedLanguageList(tmpLangList);
      var removeIndex1 = this._smartRefreshService.filterList
        .map(function (item) {
          return item.name;
        })
        .indexOf(language.name);
      this._smartRefreshService.updateFilterList(
        this.removeFilter(removeIndex1)
      );
      console.log(
        'filterList',
        this._smartRefreshService.filterList,
        this._smartRefreshService.selectedLanguageList
      );
    }
    // if (this.keyword !== '')
    this.searchResourceByFilter();
  }

  selectedEduStandard(eduStandard) {
    this._smartRefreshService.updateApiResource(null);
    console.log(eduStandard);
    this.identifier = eduStandard.standardIdentifierId;
    this._smartRefreshService.updateDefaultEducationStandard(eduStandard.name);
    this._smartRefreshService.updateDefaultCategoryList([]);
    this._smartRefreshService.updateCategoryList([]);
    this.searchResourceByIdentifier();
  }

  selectedgrade(grade) {
    this._smartRefreshService.updateApiResource(null);
    console.log(grade);
    this.identifier = grade.identifier;
    this._smartRefreshService.updateDefaultCategoryList([
      ...this._smartRefreshService.defaultCategoryList,
      grade.name,
    ]);
    this.removeSelectItem(grade);
    this.searchResourceByIdentifier();
  }

  removeSelectItem(grade) {
    console.log(this._smartRefreshService.categoryList[0].length);
    var index = null;
    for (var i = 0; i < this._smartRefreshService.categoryList.length; i++) {
      for (
        var j = 0;
        j < this._smartRefreshService.categoryList[i].length;
        j++
      ) {
        if (
          this._smartRefreshService.categoryList[i][j].identifier ==
          grade.identifier
        ) {
          index = i;
          console.log(index);
        }
      }
    }
    if (
      index != null &&
      index != this._smartRefreshService.categoryList.length - 1
    ) {
      var tmpCatList = this._smartRefreshService.categoryList;
      tmpCatList.splice(index + 1, tmpCatList.length + 1);
      this._smartRefreshService.updateCategoryList(tmpCatList);
      var tmpDefaultCatList = this._smartRefreshService.defaultCategoryList;
      tmpDefaultCatList.splice(index + 1, tmpDefaultCatList.length + 1);
      this._smartRefreshService.updateDefaultCategoryList(tmpDefaultCatList);
      // console.log(
      //   index,
      //   this._smartRefreshService.categoryList.splice(
      //     index + 1,
      //     this._smartRefreshService.categoryList.length + 1
      //   )
      // );
    }
  }

  removeFilterTag(data) {
    console.log('remove filter', data);
    //to remove selected filter
    var removeIndex1 = this._smartRefreshService.filterList
      .map(function (item) {
        return item.name;
      })
      .indexOf(data.name);
    if (removeIndex1 > -1)
      this._smartRefreshService.updateFilterList(
        this.removeFilter(removeIndex1)
      );

    switch (data.type) {
      case 'subject': {
        //to remove selected subject
        var subjectIndex = this._smartRefreshService.selectedSubjectList
          .map(function (item) {
            return item.name;
          })
          .indexOf(data.name);
        if (subjectIndex > -1) {
          var tmpSubList = this._smartRefreshService.selectedSubjectList;
          tmpSubList.splice(subjectIndex, 1);
          this._smartRefreshService.updateSelectedSubjectList(tmpSubList);
        }
        console.log(
          this._smartRefreshService.selectedSubjectList,
          subjectIndex
        );
        //to change subject select box add or subtract icon
        var subjectIndex1 = this._smartRefreshService.subjectList
          .map(function (item) {
            return item.name;
          })
          .indexOf(data.name);
        console.log(subjectIndex1);
        if (subjectIndex1 > -1) {
          var tmpSubList = this._smartRefreshService.subjectList;
          tmpSubList[subjectIndex1].selected = false;
          this._smartRefreshService.updateSubjectList(tmpSubList);
        }

        console.log(this._smartRefreshService.subjectList, subjectIndex);
        break;
      }
      case 'material': {
        var materialIndex = this._smartRefreshService.selectedMaterialList
          .map(function (item) {
            return item.name;
          })
          .indexOf(data.name);
        if (materialIndex > -1) {
          var tmpLangList = this._smartRefreshService.selectedMaterialList;
          tmpLangList.splice(materialIndex, 1);
          this._smartRefreshService.updateSelectedMaterialList(tmpLangList);
        }

        var materialIndex1 = this._smartRefreshService.materialList
          .map(function (item) {
            return item.name;
          })
          .indexOf(data.name);
        if (materialIndex1 > -1) {
          var tmpMatList = this._smartRefreshService.materialList;
          tmpMatList[materialIndex1].selected = false;
          this._smartRefreshService.updateMaterialList(tmpMatList);
        }
        break;
      }
      case 'language': {
        // var languageIndex = this.selectedLanguageList.map(function (item) { return item.name; }).indexOf(this.mapLanguageWithEnum(data.name));
        var languageIndex = this._smartRefreshService.selectedLanguageList
          .map(function (item) {
            return item.name;
          })
          .indexOf(this.mapLanguageWithEnum(data.name));
        console.log(languageIndex);
        if (languageIndex > -1) {
          var tmpLangList = this._smartRefreshService.selectedLanguageList;
          tmpLangList.splice(languageIndex, 1);
          this._smartRefreshService.updateSelectedLanguageList(tmpLangList);
        }
        console.log(
          this._smartRefreshService.selectedLanguageList,
          languageIndex
        );

        var languageIndex1 = this._smartRefreshService.languageList
          .map(function (item) {
            return item.name;
          })
          .indexOf(data.name);
        if (languageIndex1 > -1) {
          var tmpLangList = this._smartRefreshService.languageList;
          tmpLangList[languageIndex1].selected = false;
          this._smartRefreshService.updateLanguageList(tmpLangList);
        }
        break;
      }
    }
    this.searchResourceByFilter();
  }

  getSearchKeyword(searchKeyword) {
    console.log('getSearchKeyword', searchKeyword);
    console.log(
      'this._smartRefreshService.keyword',
      this._smartRefreshService.keyword
    );
    console.log(this._smartRefreshService.keyword !== searchKeyword);
    if (this._smartRefreshService.keyword !== searchKeyword) {
      this._smartRefreshService.updateApiResource(null);
      this._smartRefreshService.updateKeyword(searchKeyword);
      this.searchResourceByKeyword();
    }
  }

  searchResourceByKeyword() {
    if (!this._smartRefreshService.isBack) {
      console.log('searchResourceByKeyword calling.......');
      this.isloading = true;
      this.keywordForShow = this._smartRefreshService.keyword;
      // this._smartRefreshService.updateCategoryList([]);
      // this.identifier = '';
      // this._smartRefreshService.updateFilterList([]);
      this._smartRefreshService.updateSelectedSubjectList([]);
      this._smartRefreshService.updateSelectedLanguageList([]);
      // this._smartRefreshService.updateSelectedMaterialList([]);
      this._smartRefreshService.updateSelectedPedagogicalModel('');
      this._smartRefreshService.updateApiResource(null);
    }

    // if (this.keyword !== null && this.keyword !== '') {
    this.skip = 0;
    this.business
      .searchResource(
        this.identifier,
        this._smartRefreshService.keyword,
        this._smartRefreshService.selectedSubjectList,
        this._smartRefreshService.selectedMaterialList,
        this._smartRefreshService.selectedLanguageList,
        this._smartRefreshService.selectedPedagogicalModel,
        this.limit,
        this.skip,
        this._smartRefreshService.sortDirection,
        this._smartRefreshService.platformId
      )
      .subscribe(
        (res) => {
          this.isloading = false;
          if (!this._smartRefreshService.isBack) {
            this._smartRefreshService.updateSubjectList(
              this.resetList(this._smartRefreshService.subjectList)
            );
            this._smartRefreshService.updateMaterialList(
              this.resetList(this._smartRefreshService.materialList)
            );
            this._smartRefreshService.updateLanguageList(
              this.resetList(this._smartRefreshService.languageList)
            );
            // var eduStandardList = [];

            // for (var i = 0; i < this.resourceModel.filterMenu.length; i++) {
            //   eduStandardList.push(this.resourceModel.filterMenu[i]);
            // }
            // this._smartRefreshService.updateEduStandardList(eduStandardList);

            var mutatedPedagogicalList = [];
            for (var pModel of Object.keys(
              this.resourceModel.pedagogicalModels[0]
            )) {
              var pModelForName = pModel[0].toUpperCase() + pModel.slice(1);
              var obj = {
                name: pModelForName,
                count: this.resourceModel.pedagogicalModels[0][pModel],
              };
              mutatedPedagogicalList.push(obj);
            }
            this._smartRefreshService.updateMutatedPedagogicalList(
              mutatedPedagogicalList
            );
          }

          this.resourceModel = res[0];
          this.resourceDetailsModel = this.resourceModel.resources;
          this.blockUI.stop();
          this.isSearch = true;
        },
        (err) => {
          this.isloading = false;
          this.blockUI.stop();
          console.error(err);
        }
      );
    // }
  }

  searchResourceByIdentifier() {
    this.skip = 0;
    this.isloading = true;
    this.business
      .searchResource(
        this.identifier,
        this._smartRefreshService.keyword,
        this._smartRefreshService.selectedSubjectList,
        this._smartRefreshService.selectedMaterialList,
        this._smartRefreshService.selectedLanguageList,
        this._smartRefreshService.selectedPedagogicalModel,
        this.limit,
        this.skip,
        this._smartRefreshService.sortDirection,
        this._smartRefreshService.platformId
      )
      .subscribe(
        (res) => {
          this.isloading = false;
          this.resourceModel = res[0];
          this.resourceDetailsModel = this.resourceModel.resources;
          if (!this._smartRefreshService.isBack) {
            var gradeList = [];
            for (var i = 0; i < this.resourceModel.filterMenu.length; i++) {
              gradeList.push(this.resourceModel.filterMenu[i]);
            }
            this._smartRefreshService.updateGradeList(gradeList);
            var categoryList = this._smartRefreshService.categoryList;
            if (gradeList.length > 0) {
              gradeList = this.mutatedListForCategory(gradeList);
              categoryList.push(gradeList);
            }
            this._smartRefreshService.updateCategoryList(categoryList);

            var mutatedPedagogicalList = [];

            for (var pModel of Object.keys(
              this.resourceModel.pedagogicalModels[0]
            )) {
              var pModelForName = pModel[0].toUpperCase() + pModel.slice(1);
              var obj = {
                name: pModelForName,
                count: this.resourceModel.pedagogicalModels[0][pModel],
              };

              mutatedPedagogicalList.push(obj);
            }
            this._smartRefreshService.updateMutatedPedagogicalList(
              mutatedPedagogicalList
            );
          }
        },
        (err) => {
          console.error(err);
          this.isloading = false;
        }
      );
  }

  searchResourceByGrade() {
    this.skip = 0;
    this.isloading = true;
    this.business
      .searchResource(
        this.identifier,
        this._smartRefreshService.keyword,
        this._smartRefreshService.selectedSubjectList,
        this._smartRefreshService.selectedMaterialList,
        this._smartRefreshService.selectedLanguageList,
        this._smartRefreshService.selectedPedagogicalModel,
        this.limit,
        this.skip,
        this._smartRefreshService.sortDirection,
        this._smartRefreshService.platformId
      )
      .subscribe(
        (res) => {
          this.isloading = false;
          this.resourceModel = res[0];
          this.resourceDetailsModel = this.resourceModel.resources;
          var mutatedPedagogicalList = [];
          for (var pModel of Object.keys(
            this.resourceModel.pedagogicalModels[0]
          )) {
            var pModelForName = pModel[0].toUpperCase() + pModel.slice(1);
            var obj = {
              name: pModelForName,
              count: this.resourceModel.pedagogicalModels[0][pModel],
            };
            mutatedPedagogicalList.push(obj);
          }
          this._smartRefreshService.updateMutatedPedagogicalList(
            mutatedPedagogicalList
          );
        },
        (err) => {
          console.error(err);
          this.isloading = false;
        }
      );
  }

  searchResourceByFilter() {
    this.skip = 0;
    this.isloading = true;
    // if (this.keyword !== null && this.keyword !== '') {
    this.business
      .searchResource(
        this.identifier,
        this._smartRefreshService.keyword,
        this._smartRefreshService.selectedSubjectList,
        this._smartRefreshService.selectedMaterialList,
        this._smartRefreshService.selectedLanguageList,
        this._smartRefreshService.selectedPedagogicalModel,
        this.limit,
        this.skip,
        this._smartRefreshService.sortDirection,
        this._smartRefreshService.platformId
      )
      .subscribe(
        (res) => {
          this.isloading = false;
          this.resourceModel = res[0];
          this.resourceDetailsModel = this.resourceModel.resources;
          var mutatedPedagogicalList = [];
          console.log(
            'before mutated',
            this.resourceModel.pedagogicalModels[0]
          );
          for (var pModel of Object.keys(
            this.resourceModel.pedagogicalModels[0]
          )) {
            var pModelForName = pModel[0].toUpperCase() + pModel.slice(1);
            var obj = {
              name: pModelForName,
              count: this.resourceModel.pedagogicalModels[0][pModel],
            };
            console.log('obj:', obj);
            mutatedPedagogicalList.push(obj);
          }
          this._smartRefreshService.updateMutatedPedagogicalList(
            mutatedPedagogicalList
          );
        },
        (err) => {
          console.error(err);
          this.isloading = false;
        }
      );
    // }
  }

  searchResourceMore() {
    console.log('load more ', this._smartRefreshService.keyword, this.skip);
    this.btnLoading = true;
    // if (this.keyword !== null && this.keyword !== '') {
    this.business
      .searchResource(
        this.identifier,
        this._smartRefreshService.keyword,
        this._smartRefreshService.selectedSubjectList,
        this._smartRefreshService.selectedMaterialList,
        this._smartRefreshService.selectedLanguageList,
        this._smartRefreshService.selectedPedagogicalModel,
        this.limit,
        this.skip,
        this._smartRefreshService.sortDirection,
        this._smartRefreshService.platformId
      )
      .subscribe(
        (res) => {
          this.loadMoreText = 'Load more';
          this.btnLoading = false;
          for (var i = 0; i < res[0].resources.length; i++) {
            this.resourceDetailsModel.push(res[0].resources[i]);
          }
        },
        (err) => {
          console.error(err);
        }
      );
    // }
  }

  mutatedListForSelect(itemList) {
    var mutatedItemList = [];
    for (var i = 0; i < itemList.length; i++) {
      var obj = {
        name: itemList[i],
        selected: false,
      };
      mutatedItemList[i] = obj;
    }
    console.log('mutated list', mutatedItemList);
    return mutatedItemList;
  }

  defaultListForSelect(itemList) {
    var mutatedItemList = [];
    for (var i = 0; i < itemList.length; i++) {
      itemList[i].selected = false;
    }
    console.log('mutated list', itemList);
    return itemList;
  }

  mutatedListForCategory(itemList) {
    for (var i = 0; i < itemList.length; i++) {
      itemList[i].selected = false;
    }
    console.log('mutated list', itemList);
    return itemList;
  }

  resetList(itemList) {
    for (var i = 0; i < itemList.length; i++) {
      itemList[i].selected = false;
    }
    return itemList;
  }

  dataTransfer5E(obj) {
    this._smartRefreshService.mutatedPedagogicalList[0].count = obj.engage;
    this._smartRefreshService.mutatedPedagogicalList[1].count = obj.explore;
    this._smartRefreshService.mutatedPedagogicalList[2].count = obj.explain;
    this._smartRefreshService.mutatedPedagogicalList[3].count = obj.elaborate;
    this._smartRefreshService.mutatedPedagogicalList[4].count = obj.evaluate;
  }

  default5E() {
    this._smartRefreshService.mutatedPedagogicalList[0].count = 0;
    this._smartRefreshService.mutatedPedagogicalList[1].count = 0;
    this._smartRefreshService.mutatedPedagogicalList[2].count = 0;
    this._smartRefreshService.mutatedPedagogicalList[3].count = 0;
    this._smartRefreshService.mutatedPedagogicalList[4].count = 0;
  }

  mapLanguageWithEnum(name) {
    enum languageList {
      'English' = 'en',
      'Chinese' = 'ch',
    }
    return languageList[name];
  }

  loadMore() {
    this.loadMoreText = 'Loading...';
    this.skip += 5;
    console.log(this.skip);
    if (this.resourceModel.totalCount > this.skip) {
      this.searchResourceMore();
    }
  }

  goToDetail(item) {
    console.log(item);
    // localStorage.setItem('searchWord', this.keywordForShow);
    this.router.navigateByUrl(`/dashboard-details/${item.id}`);
  }

  selectedOrder(order) {
    console.log(order);
    this._smartRefreshService.updateSortForShow(order);
    this._smartRefreshService.updateOrderList(order);
    // enum Order {
    //   'Relevance' = 'relevance',
    //   'Name asc' = 'asc',
    //   'Name desc' = 'desc',
    //   'Date asc' = 'updatedDateASC',
    //   'Date desc' = 'updatedDateDESC',
    // }
    this._smartRefreshService.updateSortDirection(this.orderEnum[order]);
    this.showOrderList = false;
    this._smartRefreshService.updateApiResource(null);
    this.searchResourceByFilter();
  }

  addToCanvas(item) {
    console.log(this._smartRefreshService.returnUrl);
    console.log(item);
    console.log('version . . . ', this.version);

    if (this._smartRefreshService.source == 'moodle') {
      this.business
        .postUrl({
          url: item.targetFileURL,
          token: this._smartRefreshService.token,
        })
        .subscribe(
          (res) => {
            console.log('res', res);
            document
              .getElementById(item.id)
              .setAttribute(
                'href',
                `${this._smartRefreshService.returnUrl}?&embed_type=iframe&url=${item.targetFileURL}&width=${item.targetFileWidth}&height=${item.targetFileHeight}`
              );
          },
          (err) => {
            console.log('post url error', err);
          }
        );
    } else {
      document
        .getElementById(item.id)
        .setAttribute(
          'href',
          `${this._smartRefreshService.returnUrl}?&embed_type=iframe&url=${item.targetFileURL}&width=${item.targetFileWidth}&height=${item.targetFileHeight}`
        );
    }
    // if (
    //   this.version == '1.1' ||
    //   this.version == '' ||
    //   this.version == undefined ||
    //   this.version == null
    // ) {
    // } else if (this.version == '1.3')
    //   this.business.postUrl(this.returnURL).subscribe((res) => {
    //     console.log('res', res);
    //   });
  }

  // copy(text: string) {
  //     console.log("copy class code", text);
  //     var dummy = document.createElement("textarea");
  //     document.body.appendChild(dummy);
  //     dummy.value = text;
  //     dummy.select();
  //     document.execCommand("copy");
  //     document.body.removeChild(dummy);
  // }
}
