<div
  *ngFor="let category of categoryList; let index = index"
  style="margin-bottom: 16px;"
  id="height-cat"
>
  <app-select
    [boxWidth]="'286px'"
    [selectHeader]="category[0].type"
    [type]="'type1'"
    [headerClass]="'light-body-m text-c100'"
    style="margin-bottom: 8px;"
    [ngStyle]="{
      cursor: gradeList.length == 0 ? 'not-allowed' : 'cursor'
    }"
    [itemList]="category"
    [defaultSelected]="defaultSelectedCategoryList[index]"
    (selectOutput)="selectedgrade($event)"
    *ngIf="gradeList.length > 0"
  ></app-select>
</div>
