import {Component, Input} from '@angular/core';
import {ProfileStore} from './profile.store'

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
    @Input() profileImage= './assets/images/nana.jpg'
    @Input() viewChange
    constructor(private store: ProfileStore) {}
}
