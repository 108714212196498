import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface DataRequirements {
  getAllSubjects(): Observable<any>;
  getAllMaterialTypes(): Observable<any>;
  getAllLanguages(): Observable<any>;
  getAllPedagogicalModels(): Observable<any>;
  //getRelatedDetails(id): Observable<any>
  getResourceDetails(resourceID): Observable<any>;
  searchResource(
    identifier,
    keyword,
    subject,
    materialTypes,
    language,
    pedagogicalModels,
    limit,
    skip,
    sortDirection,
    platformId
  ): Observable<any>;
  postUrl(aerUrl): Observable<any>;
}

export const DataRequirementsInjectionToken =
  new InjectionToken<DataRequirements>('dashboard Data Requirements');
