import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TypeValueTableStore {

}

export enum TypeValueTableStoreKeys {

}
