import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StandardStore {

}

export enum StandardStoreKeys {

}
