import {Component, OnInit, Input} from '@angular/core';
import {TranscriptStore} from './transcript.store'

@Component({
    selector: 'app-transcript',
    templateUrl: './transcript.component.html',
    styleUrls: ['./transcript.component.css']
})
export class TranscriptComponent implements OnInit{
    @Input() resourceDetailsData
    constructor(private store: TranscriptStore) {}

  ngOnInit(): void {
  }
}
