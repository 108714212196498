<div
  *ngIf="type == 'type1'"
  [ngStyle]="{
    width: boxWidth !== null ? boxWidth : '286px',
    'min-height': boxHeight !== null ? boxHeight : '40px',
    cursor: itemList.length > 0 ? 'pointer' : 'not-allowed'
  }"
  #selectBox1
  class="select-box-1 bg-s0 d-flex align-items-center flex-column"
  (click)="clickedSelect()"
>
  <div
    class="d-flex align-items-center justify-content-between select-wrapper"
    [ngStyle]="{
      height: boxHeight !== null ? boxHeight : '40px'
    }"
  >
    <app-text [class]="headerClass">{{ getSelectHeader() }}</app-text>
    <app-image
      [src]="'./assets/icons/chevron_down_s.svg'"
      [width]="'12px'"
      [height]="'12px'"
      style="padding: 14px 16px 14px 16px;"
      [alt]="'chevron_down_s'"
      [class]="showItemList ? 'rotate180' : 'normal'"
    ></app-image>
  </div>
  <app-select-item
    [type]="'type1'"
    [itemList]="itemList"
    *ngIf="showItemList"
    [boxWidth]="boxWidth ? boxWidth : '286px'"
    [height]="'30px'"
    [width]="'286px'"
    (selectItemOutput)="selectedItem($event, true)"
    style="padding-left: 16px;"
  ></app-select-item>
</div>

<div
  *ngIf="type == 'type2'"
  [ngStyle]="{
    width: boxWidth !== null ? boxWidth : '320px',
    'min-height': boxHeight !== null ? boxHeight : '56px'
  }"
  #selectBox2
  class="select-box-2 bg-s0 d-flex align-items-center cursor flex-column"
  id="height-{{ selectHeader.slice(0, 3) }}"
>
  <div class="d-flex align-items-center" (click)="clickedSelect()">
    <app-text
      [class]="headerClass"
      style="padding-left: 16px; width: 284px; overflow-x: overlay;"
      [ngStyle]="{ 'line-height': boxHeight }"
    >
      {{ selectHeader }}
    </app-text>
    <app-image
      [src]="'./assets/icons/chevron_down_s.svg'"
      [alt]="'chevron_down_s'"
      [width]="'12px'"
      [height]="'12px'"
      style="padding: 14px 16px 14px 16px;"
      [class]="showItemList ? 'rotate180' : 'normal'"
    ></app-image>
  </div>
  <app-select-item
    [type]="'type2'"
    [itemList]="itemList"
    *ngIf="showItemList"
    [boxWidth]="'320px'"
    [height]="'40px'"
    [width]="'286px'"
    (selectItemOutput)="selectedItem($event, true)"
  ></app-select-item>
</div>
