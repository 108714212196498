import {Component, Input, Output, EventEmitter} from '@angular/core';
import {CustomLoadingStore} from './custom-loading.store'

@Component({
    selector: 'app-custom-loading',
    templateUrl: './custom-loading.component.html',
    styleUrls: ['./custom-loading.component.css']
})
export class CustomLoadingComponent {
    constructor(private store: CustomLoadingStore) {}
    @Input() className='';
}
