import {Component} from '@angular/core';
import {HomePageLoadingStore} from './home-page-loading.store'

@Component({
    selector: 'app-home-page-loading',
    templateUrl: './home-page-loading.component.html',
    styleUrls: ['./home-page-loading.component.css']
})
export class HomePageLoadingComponent {
    constructor(private store: HomePageLoadingStore) {}
}
