<div class="order-group d-flex align-items-end flex-column">
  <div
    class="order d-flex align-items-center cursor"
    (click)="clickedOrder()"
    [ngStyle]="{ background: showOrderList ? '#D9ECFF' : 'transparent' }"
  >
    <app-text class="h5-strong text-b100" style="padding: 0px 8px 0px 16px;">
      {{ orderForShow ? orderForShow : 'Date desc' }}
    </app-text>
    <app-text class="img-16 d-flex align-items-center justify-content-center">
      <app-image
        [src]="'./assets/icons/chevron_down_s_b.svg'"
        [alt]="'sort'"
      ></app-image>
    </app-text>
  </div>
  <span class="triangle"></span>
  <div class="order-list bg-s0" *ngIf="showOrderList">
    <div
      class="d-flex align-items-center order-list-item cursor"
      *ngFor="let item of orderList; let index = index"
      (click)="selectOrder(index)"
    >
      <app-text
        style="width: 20px; height: 20px; margin-right: 8px;"
        class="d-flex align-items-center"
      >
        <app-image
          [src]="'./assets/icons/tick.svg'"
          *ngIf="item.selected"
        ></app-image>
      </app-text>
      <app-text class="body-m text-b100">{{ item.name }}</app-text>
    </div>
  </div>
</div>
