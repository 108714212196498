<div class="loading-wrapper">
    <div>
        <app-custom-loading [className]="'posterLoading1'"></app-custom-loading>
        <app-custom-loading [className]="'posterLoading2'"></app-custom-loading>
    </div>
    <div>
        <app-custom-loading [className]="'contentLoading1'"></app-custom-loading>
        <app-custom-loading [className]="'contentLoading2'"></app-custom-loading>
        <app-custom-loading [className]="'contentLoading3'"></app-custom-loading>
        <app-custom-loading [className]="'contentLoading3'"></app-custom-loading>
        <app-custom-loading [className]="'contentLoading3'"></app-custom-loading>
        <app-custom-loading [className]="'contentLoading4'"></app-custom-loading>
        <div class="d-flex">
            <app-custom-loading [className]="'contentLoading5'"></app-custom-loading>
            <app-custom-loading [className]="'contentLoading5'"></app-custom-loading>
        </div>
    </div>
    <div>
        <app-custom-loading [className]="'subLoading1'"></app-custom-loading>
        <app-custom-loading [className]="'subLoading1'"></app-custom-loading>
        <app-custom-loading [className]="'subLoading1'"></app-custom-loading>
        <app-custom-loading [className]="'subLoading1'"></app-custom-loading>
        <app-custom-loading [className]="'subLoading1'"></app-custom-loading>
    </div>
    <div>
        <app-custom-loading [className]="'subLoading2'"></app-custom-loading>
        <app-custom-loading [className]="'subLoading2'"></app-custom-loading>
        <app-custom-loading [className]="'subLoading2'"></app-custom-loading>
        <app-custom-loading [className]="'subLoading2'"></app-custom-loading>
        <app-custom-loading [className]="'subLoading2'"></app-custom-loading>
    </div>
    <div>
        <app-custom-loading [className]="'logoLoading1'"></app-custom-loading>
        <app-custom-loading [className]="'logoLoading2'"></app-custom-loading>
    </div>
</div>