import {Component, Input, OnInit} from '@angular/core';
import {DetailStore} from './detail.store'

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit{
   @Input() resourceDetailsData

    constructor(private store: DetailStore) {}
  ngOnInit(): void {
    console.log(this.resourceDetailsData);
  }
}
