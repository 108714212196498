import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import { ResourceDetailsMapper } from '../mappers/resource-details.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetResourceDetailsUseCase {
    constructor(
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements,
        private mapper: ResourceDetailsMapper
    ) {}

    run(resourceID) {
      return this.data.getResourceDetails(resourceID).pipe(this.mapper.map);
    }
}
