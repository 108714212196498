import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavFilterStore {

}

export enum NavFilterStoreKeys {

}
