import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchResultStore } from './search-result.store';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css'],
})
export class SearchResultComponent implements OnInit {
  @Input() resource;
  @Input() viewChange;
  @Input() keyword;

  @Output() outputForDetail = new EventEmitter();
  @Output() buttonOutput = new EventEmitter();
  constructor(private store: SearchResultStore) {}

  public borderClass = '';

  ngOnInit() {
    if (this.viewChange) this.borderClass = 'br-0';
    else this.borderClass = 'br-4';
    console.log(this.resource.pedagogicalModels);
  }

  clickedName(item) {
    this.outputForDetail.emit(item);
  }

  addToCanvas(item) {
    console.log(item);
    this.buttonOutput.emit(item);
  }
}
