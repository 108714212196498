export class FilterMenuModel {
  ancestors: any = [];
  createdAt: string = '2020-07-10T04:13:57.114Z';
  identifier: string = '30016616-ba83-11ea-a98d-0242c0a82003';
  relations: any = [];
  standardIdentifierId: string = '30016616-ba83-11ea-a98d-0242c0a82003';
  name: string = 'Amdon (AER Demo)';
  type: string = 'Topic';
  updatedAt: string = '2020-07-10T04:13:57.114Z';
  uri: string =
    'https://frameworks.werkzpublishing.com/uri/30016616-ba83-11ea-a98d-0242c0a82003';
  version: number = 0;
  id: string = '5f07eb054f69950012f25e8c';
  fullStatement: string = 'Amdon (AER Demo)';
}
