import {Component} from '@angular/core';
import {NoResultsFoundStore} from './no-results-found.store'

@Component({
    selector: 'app-no-results-found',
    templateUrl: './no-results-found.component.html',
    styleUrls: ['./no-results-found.component.css']
})
export class NoResultsFoundComponent {
    constructor(private store: NoResultsFoundStore) {}
}
