import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { SelectComponent } from './select/select.component';
import { GlobalComponentsModule } from 'src/app/global-components/global-components.module';
import { SelectItemComponent } from './select-item/select-item.component';
import { TagComponent } from './tag/tag.component';
import { NavFilterComponent } from './nav-filter/nav-filter.component';
import { ProfileComponent } from './profile/profile.component';
import { RelatedCardComponent } from './related-card/related-card.component';

const components = [
    SelectComponent,
    SelectItemComponent,
    TagComponent,
    NavFilterComponent,
    ProfileComponent,
    RelatedCardComponent
]

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        GlobalComponentsModule
    ]
})
export class SharedComponentsModule {}
