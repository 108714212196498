import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {TagStore} from './tag.store'

@Component({
    selector: 'app-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit{
    @Input() type;
    @Input() tagName;
    @Input() class;
    @Output() tagOutput = new EventEmitter;
    constructor(private store: TagStore) {}

    ngOnInit(){
        console.log(typeof(this.tagName))
        // if(typeof(this.tagName) == 'object'){
        //     this.tagName= this.tagName.name
        // }
        console.log(this.class)
    }
    clickedTag(name){
        this.tagOutput.emit(name)
    }
}
