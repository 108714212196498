import { ItemMapper } from 'src/app/lib/item-mapper';
import { Locales, ResourceDetailsModel } from 'src/app/models/resource-details.model';


export class ResourceDetailsMapper extends ItemMapper<any> {
    protected mapFromItem(item): any {
        console.log('hi',item)


          var resourceDetailsModel = new ResourceDetailsModel
          resourceDetailsModel.accessMode = item.accessMode
          resourceDetailsModel.accessibilityHazards = item.accessibilityHazards
          resourceDetailsModel.aerGUID = item.aerGUID
          resourceDetailsModel.aerReferenceID = item.aerReferenceID
          resourceDetailsModel.author = item.author
          resourceDetailsModel.createdAt = item.createdAt
          resourceDetailsModel.description = item.description
          resourceDetailsModel.educationalAudience = item.educationalAudience
          // resourceDetailsModel.language = item.language
          resourceDetailsModel.language = this.mutatedLanguage(item.language)
          resourceDetailsModel.languageRegiondesignator = item.languageRegiondesignator
          resourceDetailsModel.learningObjectives = item.learningObjectives
          resourceDetailsModel.learningResourceType = item.learningResourceType
          
          resourceDetailsModel.name = item.name
          resourceDetailsModel.pedagogicalModels = item.pedagogicalModels
          resourceDetailsModel.publisher = item.publisher
          resourceDetailsModel.related = item.related
          resourceDetailsModel.standardNodesInfo = item.standardNodesInfo
          resourceDetailsModel.subject = item.subject
          resourceDetailsModel.targetFileHeight = item.targetFileHeight
          resourceDetailsModel.targetFileThumbnailURL = item.targetFileThumbnailURL
          resourceDetailsModel.targetFileURL = item.targetFileURL
          resourceDetailsModel.targetFileWidth = item.targetFileWidth
          resourceDetailsModel.technicalFormat = item.technicalFormat
          resourceDetailsModel.textComplexity = item.textComplexity
          resourceDetailsModel.timeRequired = item.timeRequired
          resourceDetailsModel.transcriptURL = item.transcriptURL
          resourceDetailsModel.age = item.typicalAgeRange
          resourceDetailsModel.updatedAt = item.updatedAt
          resourceDetailsModel.version  = item.__v
          resourceDetailsModel.id = item._id

          if(item.locales && item.locales['targetFileURL']) {

            resourceDetailsModel.locales = new Locales(resourceDetailsModel.targetFileURL, item.locales['targetFileURL']);

          }


        return resourceDetailsModel
    }

    mutatedLanguage(originalLanguageList){

      enum languageList {
          'en' = 'English',
          'ch' = 'Chinese'
      }

      for(var i=0; i < originalLanguageList.length;i++){
          var temp = originalLanguageList[i]
          originalLanguageList[i]=languageList[originalLanguageList[i]]
      }
      return originalLanguageList
  }
}
