import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
} from '@angular/core';
import { OrderListStore } from './order-list.store';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css'],
})
export class OrderListComponent implements OnInit {
  @Input() showOrderList;
  @Input() orderList;
  @Input() orderForShow;

  @Output() orderOutput = new EventEmitter();

  constructor(private store: OrderListStore, private elementRef: ElementRef) {}

  ngOnInit() {
    console.log('order list', this.showOrderList, this.orderList);
    console.log('orderForShow', this.orderForShow);
  }

  @HostListener('document:click', ['$event'])
  outside(event) {
    // if (
    //   event.target.innerText != 'Date asc' &&
    //   event.target.innerText != 'Date desc' &&
    //   event.target.innerText != 'Name asc' &&
    //   event.target.innerText != 'Name desc' &&
    //   event.target.innerText != 'Relevance' &&
    //   event.target.alt != 'sort'
    // ) {
    //   console.log('outside', event.target.innerText, event.target);
    //   if (event.target.id && event.target.id == 'search')
    //     this.showOrderList = false;
    // }
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.showOrderList = false;
    }
  }

  selectOrder(index) {
    // for (var i = 0; i < this.orderList.length; i++) {
    //   if (i == index) this.orderList[i].selected = true;
    //   else this.orderList[i].selected = false;
    // }
    this.showOrderList = false;
    this.orderOutput.emit(this.orderList[index].name);
  }

  clickedOrder() {
    this.showOrderList = !this.showOrderList;
  }
}
