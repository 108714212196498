import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, share } from 'rxjs/operators';
import { SmartRefreshService } from 'src/app/lib/smartRefresh.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceApiService {
  constructor(
    private httpClient: HttpClient,
    private _smartRefreshService: SmartRefreshService
  ) {}

  private baseUrl = environment.apiurl;

  getAllSubjects(): Observable<any> {
    let url = this.baseUrl + '/subjects';
    // let url = 'https://devaer-api.pagewerkz.com/subjects';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // username: this.userInfo.username,
        // password: this.userInfo.password,
        // api_key: this.userInfo.api_key
      }),
    };
    return this.httpClient.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        console.log('subject list', res);
        return result;
      })
    );
  }

  getAllMaterialTypes(): Observable<any> {
    let url = this.baseUrl + '/materialTypes';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // username: this.userInfo.username,
        // password: this.userInfo.password,
        // api_key: this.userInfo.api_key
      }),
    };
    return this.httpClient.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        console.log('material type list', res);
        return result;
      })
    );
  }

  getAllLanguages(): Observable<any> {
    let url = this.baseUrl + '/languages';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // username: this.userInfo.username,
        // password: this.userInfo.password,
        // api_key: this.userInfo.api_key
      }),
    };
    return this.httpClient.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        console.log('language list', res);
        return result;
      })
    );
  }

  getAllPedagogicalModels(): Observable<any> {
    let url = this.baseUrl + '/pedagogicalModels';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // username: this.userInfo.username,
        // password: this.userInfo.password,
        // api_key: this.userInfo.api_key
      }),
    };
    return this.httpClient.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = res;
        console.log('pedagogicalModels list', res);
        return result;
      })
    );
  }

  searchResource(
    identifier,
    keyword,
    subject,
    materialTypes,
    language,
    pedagogicalModels,
    limit,
    skip,
    sortDirection,
    platformId
  ): Observable<any> {
    let url =
      this.baseUrl +
      '/dashboard/resources?identifier=' +
      identifier +
      '&keyword=' +
      keyword +
      '&subject=' +
      subject +
      '&materialTypes=' +
      materialTypes +
      '&language=' +
      language +
      '&pedagogicalModels=' +
      pedagogicalModels +
      '&limit=' +
      limit +
      '&skip=' +
      skip +
      '&sortDirection=' +
      sortDirection +
      '&platformId=' +
      platformId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // username: this.userInfo.username,
        // password: this.userInfo.password,
        // api_key: this.userInfo.api_key
      }),
    };
    console.log('search resource skip', skip);
    if (this._smartRefreshService.apiResource != null && skip == 0) {
      return of(this._smartRefreshService.apiResource);
    } else {
      return this.httpClient.get(url, httpOptions).pipe(
        map(
          (res: Response) => {
            let result = [];
            result.push(res);
            console.log('resource list', res);
            this._smartRefreshService.updateApiResource(result);
            this._smartRefreshService.updateIsBack(false);
            return result;
          },
          (err) => {
            console.log(err);
            this._smartRefreshService.updateApiResource(null);
            this._smartRefreshService.updateIsBack(false);
          }
        )
      );
    }
  }

  getResourceDetails(resourceID): Observable<any> {
    if (this._smartRefreshService.previousDetailId == resourceID) {
      return of(this._smartRefreshService.apiResourceDetail);
    } else {
      console.log('API HERE');
      let url = this.baseUrl + '/api/v1/resources/' + resourceID;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // username: this.userInfo.username,
          // password: this.userInfo.password,
          // api_key: this.userInfo.api_key
        }),
      };
      return this.httpClient.get(url, httpOptions).pipe(
        map((res: Response) => {
          let result = [];
          result.push(res);
          console.log('this.getResourceDetails()', res);
          this._smartRefreshService.updatePreviousDetailId(resourceID);
          this._smartRefreshService.updateApiResourceDetail(result);
          return result;
        })
      );
    }
  }

  postUrl(aerUrl): Observable<any> {
    console.log('aerUrl', aerUrl);
    let url = this.baseUrl + '/deeplink';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // username: this.userInfo.username,
        // password: this.userInfo.password,
        // api_key: this.userInfo.api_key
      }),
    };
    // const body = {
    //   url: aerUrl,
    // };
    console.log('body', aerUrl);
    return this.httpClient.post(url, aerUrl, httpOptions).pipe(
      map((res: Response) => {
        let result = [];
        result.push(res);
        console.log('this.postUrl()', res);
        return result;
      })
    );
  }
}
