import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DropdownOptionModel } from 'src/app/models/dropdown-option.model';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
})
export class DropdownComponent implements OnInit {
  @Input()
  options: Array<DropdownOptionModel>;
  @Input()
  hint: string = 'Select';
  @Input()
  width: number = 200;

  @Output()
  onOptionSelected = new EventEmitter<DropdownOptionModel>();

  public showDropdown = false;
  public selectedOption: DropdownOptionModel = null;

  constructor() {}

  toggleDropdown(e: any) {
    e.stopPropagation();
    e.preventDefault();

    this.showDropdown = !this.showDropdown;
  }

  onOptionSelect(e: any, option: DropdownOptionModel) {
    // e.stopPropagation();
    // e.preventDefault();

    this.selectedOption = Object.assign({}, option);
    this.onOptionSelected.emit(this.selectedOption);
  }

  @HostListener('document:click')
  onOutsideClick() {
    if (this.showDropdown) {
      this.showDropdown = false;
    }
  }

  ngOnInit(): void {}
}
