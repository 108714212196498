import { Component, ElementRef, ViewChild, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SelectItemStore } from './select-item.store';

@Component({
  selector: 'app-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.css'],
})
export class SelectItemComponent implements OnInit {
  @Input() itemList;
  @Input() boxWidth;
  @Input() width = 'auto';
  @Input() height = 'auto';
  @Input() type;

  @Output() selectItemOutput = new EventEmitter();

  @ViewChild('tipMasteryWrapper', { static: false }) tipContent: ElementRef;

  public addIcon = './assets/icons/add_small.svg';
  public substractIcon = './assets/icons/substract_small.svg';
  public isTooltip = false;
  public tooltipIdx: any;
  public tooltipContent: string;

  constructor(private store: SelectItemStore) {}
  ngOnInit(): void {
    console.log('select item component');
    console.log(this.itemList, this.type);
    console.log(this.boxWidth);
  }
  selectItem(item) {
    console.log(item);
    this.isTooltip = false;
    if (this.type === 'type2') {
      this.itemList.map((i) => {
        if (i.name === item.name) {
          i.selected = !i.selected;
          this.selectItemOutput.emit(i);
        }
      });
    } else if (this.type === 'type1') {
      this.selectItemOutput.emit(item);
    }
  }

  onMouseLeave() {
    this.isTooltip = false;
    document.getElementById('tipMasteryWrapper').style.top = '0px';
  }

  onMouseEnter(obj:any) {
    console.log('obj ',obj);
    var name = obj.name? obj.name : obj.title;
    if(name.length > 30){
      this.isTooltip = true;
      this.tooltipContent = name;
      this.tooltipIdx = obj.idx;
      var cursorX = obj.e.pageX-30;
      var cursorY = obj.e.pageY;
      
      var element = document.getElementById('tipMasteryWrapper'),
      elemRect = element.getBoundingClientRect(),
      offset   = (cursorY - elemRect.top)-50;
      // console.log('elemRect.top ', elemRect.top);
      // console.log('offset ', offset);
      // console.log('name.length ',name.length);

      if (name.length > 66){
        offset = offset - 40;
        document.getElementById('arrow-tip').style.marginTop = '90px';
      }else if(name.length > 33){
        offset = offset - 20;
        document.getElementById('arrow-tip').style.marginTop = '64px';
      } else {
        document.getElementById('arrow-tip').style.marginTop = '40px';
      }

      document.getElementById('tipMasteryWrapper').style.left = cursorX + 'px';
      document.getElementById('tipMasteryWrapper').style.top = offset + 'px';

      // (<HTMLDivElement>this.tipContent.nativeElement).style.left = cursorX + 'px';
      // (<HTMLDivElement>this.tipContent.nativeElement).style.top = cursorY + 'px';
      
    }
  }
}
