import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NoResultsFoundStore {

}

export enum NoResultsFoundStoreKeys {

}
