<button [disabled]="disabled" (click)="onButtonClick($event)" class="{{additionalCSS}}" [ngClass]="{
    'bg-s40 body-m text-s70 cursor-disable': disabled ,
    'button-tertiary bg-s20 body-m text-c100': class=='button-tertiary' && !disabled,
    'button-primary bg-b100 body-m text-s0': class=='button-primary' && !disabled,
    'button-secondary bg-s0 body-m text-b100': class=='button-secondary' && !disabled,
    'button-red text-s0': class=='button-red' && !disabled,
    'back-button': class=='back-button' && !disabled,
    'btnDesc': class=='btnDesc' && !disabled,
    'btnTrans': class=='btnTrans' && !disabled,
    class: (class!='button-tertiary' && class!='button-primary' && class!='button-secondary')
}" [ngStyle]="{'width': width? width: 'auto' , 'height': height? height: 'auto'   }">
    <ng-content></ng-content>
</button>
