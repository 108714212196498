<div
  class="bg-s0 item1"
  [ngStyle]="{ width: boxWidth }"
  *ngIf="type === 'type1' && itemList.length > 0"
  [ngClass]="{ item1_1: boxWidth == '286px', item1_2: boxWidth != '286px' }"
>
  <!-- <div #tipMasteryWrapper> -->
    <div id="tipMasteryWrapper" [ngClass]="isTooltip? 'show-tooltip':'hide-tooltip'"  class="tip-content-wrapper">
      <app-text [class]="'light-body-m text-b25'">{{
        tooltipContent
      }}</app-text>
      <div id="arrow-tip"></div>
    </div>
    
  <!-- </div> -->
  
  <div *ngFor="let item of itemList; let index = index">
    <div
      class="d-flex align-items-center bg-s0 subject cursor"
      [ngStyle]="{
        width: boxWidth,
        height: height
      }"
      (mouseleave)="onMouseLeave()"
      (mouseenter)="onMouseEnter({ e: $event, name: item.name, title: item.title, idx: index })"
      (click)="selectItem(item)"
    >
      <app-text
        *ngIf="item.name"
        class="light-body-m text-c300"
        style="padding-left: 16px; overflow-x: overlay;"
      >
        {{ item.name.length > 30 ? item.name.slice(0, 30) + '...' : item.name }}
      </app-text>
      <app-text
        *ngIf="item.title"
        class="light-body-m text-c300"
        style="padding-left: 16px; overflow-x: overlay;"
      >
        {{
          item.title.length > 30 ? item.title.slice(0, 30) + '...' : item.title
        }}
      </app-text>
    </div>
  </div>
  
</div>

<div
  class="item2"
  [ngStyle]="{ width: boxWidth }"
  *ngIf="type === 'type2' && itemList.length > 0"
>
  <div
    class="d-flex align-items-center bg-s0 subject cursor"
    *ngFor="let item of itemList"
    [ngStyle]="{
      width: width,
      height: height
    }"
    (click)="selectItem(item)"
  >
    <app-image
      [src]="substractIcon"
      style="margin: 0px 8px 0px 8px; display: flex; align-items: center;"
      *ngIf="item.selected"
    ></app-image>
    <app-image
      [src]="addIcon"
      style="margin: 0px 8px 0px 8px; display: flex; align-items: center;"
      *ngIf="!item.selected"
    ></app-image>
    <app-text class="light-body-m text-c300">
      {{ item.name.length > 30 ? item.name.slice(0, 30) + '...' : item.name }}
    </app-text>
    <!-- <app-text class="light-body-m text-c300">
      {{
        item.title.length > 30 ? item.title.slice(0, 30) + '...' : item.title
      }}
    </app-text> -->
  </div>
</div>
