import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HomePageLoadingStore {

}

export enum HomePageLoadingStoreKeys {

}
