import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class OrderListStore {

}

export enum OrderListStoreKeys {

}
