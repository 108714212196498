import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {NavFilterStore} from './nav-filter.store'

@Component({
    selector: 'app-nav-filter',
    templateUrl: './nav-filter.component.html',
    styleUrls: ['./nav-filter.component.css']
})
export class NavFilterComponent implements OnInit{
    @Input() itemList;
    @Input() selecteditem;
    @Input() viewChange

    @Output() navFilterOutput = new EventEmitter()

    constructor(private store: NavFilterStore) {}
    ngOnInit(): void {
        console.log(this.itemList)
    }

    select5E(item){
        if(item.count > 0)
        this.navFilterOutput.emit(item)
    }

    left(){
        console.log(document.getElementById('pedagogical').scrollLeft)
        if(document.getElementById('pedagogical').scrollLeft > 0)
        document.getElementById('pedagogical').scrollLeft = 0
    }

    right(){
        console.log(document.getElementById('pedagogical').scrollLeft)
        if(document.getElementById('pedagogical').scrollLeft == 0)
        document.getElementById('pedagogical').scrollLeft = 140
    }
}
