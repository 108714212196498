import { Inject, Component, OnInit, HostListener } from '@angular/core';
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from '../business-logic.requirements';
import { DashboardDetailsStore } from './dashboard-details.store';
import { Router, ActivatedRoute } from '@angular/router';
import { NgModule } from '@angular/core';
import { DropdownOptionModel } from 'src/app/models/dropdown-option.model';
import {
  Locales,
  ResourceDetailsModel,
} from 'src/app/models/resource-details.model';
import { DomSanitizer } from '@angular/platform-browser';
import { SmartRefreshService } from 'src/app/lib/smartRefresh.service';

@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details-controller.component.html',
  styleUrls: ['./dashboard-details-controller.component.css'],
})
export class DashboardDetailsControllerComponent implements OnInit {
  public showLanguageChooser = false;
  public languageOptions: Array<DropdownOptionModel> = [];
  public iframeHeight = 650;

  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: DashboardDetailsStore,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public _smartRefreshService: SmartRefreshService
  ) {}
  public showPopup = false;
  public fileType: any;
  public oamUrl: any;
  public safeUrl: any;
  public isLanguageChange = false;

  public platformId = null;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      console.log(params);
      console.log(params['topic_id']);
      this.resourceID = params['topic_id'];
      this.returnURL = params['param'];
      this.version = params['version'];
      this.platformId = params['platformId'];
      // this.returnURL = 'http://canvas-temp.pagewerkz.com/courses/4/pages' //for test
      console.log(params);
      console.log('returnURL ', this.returnURL);
    });
    this.business.getResourceDetails(this.resourceID).subscribe((res) => {
      if (res.length != 0) {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        this.resourceDetailsData = res[0];
        this.isShowApiData = true;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.resourceDetailsData['targetFileURL']
        );
        this.urlForOAMInSelectedLocale =
          this.resourceDetailsData['targetFileURL'];
        // this.safeUrl = this.resourceDetailsData['targetFileURL'];
        console.log(window.innerWidth);
        if (window.innerWidth < this.resourceDetailsData['targetFileWidth'])
          this.iframeHeight =
            (window.innerWidth * this.resourceDetailsData['targetFileHeight']) /
            this.resourceDetailsData['targetFileWidth'];
        else this.iframeHeight = this.resourceDetailsData['targetFileHeight'];

        let locales: Locales = this.resourceDetailsData['locales'];
        console.log('RelatedAERs', this.resourceDetailsData['related']);

        if (locales) {
          this.showLanguageChooser = locales.checkIsMultiLingual();
          this.languageOptions = locales.getLanguageOptions().map(
            (locale) =>
              <DropdownOptionModel>{
                value: locale.code,
                label: locale.label,
              }
          );
        }
      }
    });
  }
  public resourceID;
  public resourceDetailsData = [];
  public name = 'Hello';
  public apiData = [];
  public isShowDetails = true;
  public isShowTranscript = false;
  public isShowApiData = false;
  public eduStandardList = [
    { name: 'Core Standard', selected: false },
    { name: 'Amdon (ACR)', selected: false },
    { name: 'Amdon (AER)', selected: false },
    { name: 'Core (AER)', selected: false },
  ];
  public showPermanentLink = false;
  public returnURL = '';
  public version = '';

  //
  // @HostListener('document:click', ['$event'])
  // outside($event) {
  //   if (this.showPermanentLink == true && ($event.target.innerText !== 'Permanent Link' || $event.target.innerText == 'Permanent Link'))
  //     this.showPermanentLink = false;
  //   else if ($event.target.innerText == 'Permanent Link' && this.showPermanentLink == false)
  //     this.showPermanentLink = true;
  // }
  //
  public urlForOAMInSelectedLocale = null;
  onLanguageOptionChanged(language: DropdownOptionModel) {
    console.log('OnLanguageOptionChanged', language);
    let locales: Locales = this.resourceDetailsData['locales'];
    this.urlForOAMInSelectedLocale = locales
      ? locales.getUrlByLocale(language.value)
      : '';

    console.log('AER TargetURL', this.urlForOAMInSelectedLocale);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.urlForOAMInSelectedLocale
    );
    this.isLanguageChange = true;
    var _this = this;
    setTimeout(function () {
      _this.isLanguageChange = false;
      console.log(_this.isLanguageChange);
    }, 2000);
  }

  toggleDisplayDetails() {
    console.log('Toggle Play Details');
    this.isShowDetails = true;
    this.isShowTranscript = false;
  }
  //
  toggleDisplayTranscript() {
    console.log('Toggle Play Transcript');
    this.isShowDetails = false;
    this.isShowTranscript = true;
  }
  backToDashboard() {
    console.log('Back to Dashboard');
    this._smartRefreshService.updateIsBack(true);
    this.router.navigate(['/dashboard']);
  }
  public isShow = false;
  hover() {
    if (this.resourceDetailsData['technicalFormat'] !== 'HTML')
      this.isShow = true;
  }
  notHover() {
    if (this.resourceDetailsData['technicalFormat'] !== 'HTML')
      this.isShow = false;
  }

  clickedPermanentLink() {
    console.log('permanentUrl', this.safeUrl);

    this.showPermanentLink = !this.showPermanentLink;
  }

  goToDetail(relatedID) {
    console.log(relatedID);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(`/dashboard-details/${relatedID}`);
    this.ngOnInit();
  }

  copy(text: string) {
    this.showPermanentLink = false;
    console.log('copy class code', text);
    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }

  addToCanvas() {
    console.log(this.returnURL);
    var item: any = this.resourceDetailsData;
    console.log(item);

    console.log(this._smartRefreshService.returnUrl);
    console.log(item);
    console.log('version . . . ', this.version);

    if (this._smartRefreshService.source == 'moodle') {
      this.business
        .postUrl({
          url: item.targetFileURL,
          token: this._smartRefreshService.token,
        })
        .subscribe(
          (res) => {
            console.log('res', res);
            document
              .getElementById(item.id)
              .setAttribute(
                'href',
                `${
                  this._smartRefreshService.returnUrl
                }?&embed_type=iframe&url=${
                  this.urlForOAMInSelectedLocale
                    ? this.urlForOAMInSelectedLocale
                    : item.targetFileURL
                }&width=${item.targetFileWidth}&height=${item.targetFileHeight}`
              );
          },
          (err) => {
            console.log('post url error', err);
          }
        );
    } else {
      document
        .getElementById(item.id)
        .setAttribute(
          'href',
          `${this._smartRefreshService.returnUrl}?&embed_type=iframe&url=${
            this.urlForOAMInSelectedLocale
              ? this.urlForOAMInSelectedLocale
              : item.targetFileURL
          }&width=${item.targetFileWidth}&height=${item.targetFileHeight}`
        );
    }
    // if (
    //   this.version == '1.1' ||
    //   this.version == '' ||
    //   this.version == undefined ||
    //   this.version == null
    // ) {
    // } else if (this.version == '1.3')
    //   this.business.postUrl(this.returnURL).subscribe((res) => {
    //     console.log('res', res);
    //   });
  }
  // clickPlayBtn(data:any){
  //   console.log(data);
  //   this.oamUrl = data.targetFileURL;
  //   this.fileType = data.fileFormat;
  //   console.log('oamUrl ', this.oamUrl);
  //   this.showPopup = true;
  //   const body = document.getElementsByTagName('body')[0];
  //   body.classList.add('modal-open');
  // }

  // closePopupFunciton(){
  //   this.showPopup = false;
  //   const body = document.getElementsByTagName('body')[0];
  //   body.classList.remove('modal-open');
  // }
}
