<div class="top-filter d-flex align-items-center" *ngIf="!viewChange">
    <div class="d-flex flex-column cursor" style="padding:10px 16px;" *ngFor="let item of itemList" (click)="select5E(item)" [ngStyle]="{'background' : selecteditem == item.name ? '#0065F2' : '#FFFFFF'}">
        <app-text style="width: 100%;" class="d-flex justify-content-center h5-strong" [ngClass]="{
            'text-c300' : item.count > 0,
            'text-s70'  : item.count == 0 && item.name != selecteditem,
            'text-s0' : item.name == selecteditem
        }">{{item.name}}</app-text>
        <app-text style="width: 100%;" class="d-flex justify-content-center caption" [ngClass]="{
            'text-s0' : item.name == selecteditem,
            'text-c75' : item.name != selecteditem
        }">{{item.count > 0 ? item.count : '-'}}</app-text>
    </div>
</div>

<div class="top-filter d-flex align-items-center bg-s0" *ngIf="viewChange" style="width: 379.41px;">
    <div class="arrow-left bg-s0 d-flex align-items-center justify-content-center" (click)="left()">
        <app-image [src]="'./assets/icons/chevron_left.svg'"></app-image>
    </div>
    <div class="pedagogical d-flex" id="pedagogical">
        <div class="d-flex flex-column cursor" style="padding:10px 16px;" *ngFor="let item of itemList; let first= first; let last = last" (click)="select5E(item)" [ngStyle]="{'background' : selecteditem == item.name ? '#0065F2' : '#FFFFFF'}">
            <app-text style="width: 100%;" class="d-flex justify-content-center h5-strong" [ngClass]="{
                'text-c300' : item.count > 0,
                'text-s70'  : item.count == 0 && item.name != selecteditem,
                'text-s0' : item.name == selecteditem
            }" id="{{first ? 'first' : last ? 'last' : 'none'}}">{{item.name}}</app-text>
            <app-text style="width: 100%;" class="d-flex justify-content-center caption" [ngClass]="{
                'text-s0' : item.name == selecteditem,
                'text-c75' : item.name != selecteditem
            }">{{item.count > 0 ? item.count : '-'}}</app-text>
        </div>
    </div>
    <div class="arrow-right bg-s0 d-flex align-items-center justify-content-center" (click)="right()">
        <app-image [src]="'./assets/icons/chevron_right.svg'"></app-image>
    </div>
</div>