import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { ButtonComponent } from './button/button.component';
import { ImageComponent } from './image/image.component';
import { InputComponent } from './input/input.component';
import { SearchComponent } from './search/search.component';
import { TextComponent } from './text/text.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { CustomLoadingComponent } from './custom-loading/custom-loading.component';

const components = [
    ButtonComponent,
    ImageComponent,
    InputComponent,
    SearchComponent,
    TextComponent,
    DropdownComponent,
    CustomLoadingComponent
]

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        ReactiveFormsModule,
    ]
})
export class GlobalComponentsModule {}
