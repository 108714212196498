import { DataRequirements } from '../business-logic/data.requirements';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ResourceApiService } from './api-services/resource-api.service';

@NgModule({
  imports: [HttpClientModule],
})
export class DataFacade implements DataRequirements {
  constructor(private resourceApiService: ResourceApiService) {}

  getResourceDetails(resourceID): Observable<any> {
    console.log(resourceID);
    return this.resourceApiService.getResourceDetails(resourceID);
  }

  getAllMaterialTypes(): Observable<any> {
    return this.resourceApiService.getAllMaterialTypes();
    // return of(true)
  }

  getAllLanguages(): Observable<any> {
    return this.resourceApiService.getAllLanguages();
    // return of(true)
  }

  getAllSubjects(): Observable<any> {
    return this.resourceApiService.getAllSubjects();
  }

  getAllPedagogicalModels(): Observable<any> {
    return this.resourceApiService.getAllPedagogicalModels();
  }

  searchResource(
    identifier,
    keyword,
    subject,
    materialTypes,
    language,
    pedagogicalModels,
    limit,
    skip,
    sortDirection,
    platformId
  ): Observable<any> {
    console.log(limit, skip);
    return this.resourceApiService.searchResource(
      identifier,
      keyword,
      subject,
      materialTypes,
      language,
      pedagogicalModels,
      limit,
      skip,
      sortDirection,
      platformId
    );
  }

  postUrl(aerUrl): Observable<any> {
    return this.resourceApiService.postUrl(aerUrl);
  }
}
