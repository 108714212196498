import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { StandardStore } from './standard.store';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { stripSummaryForJitNameSuffix } from '@angular/compiler/src/aot/util';
import { DropdownOptionModel } from 'src/app/models/dropdown-option.model';
import { SelectComponent } from '../../../shared-components/select/select.component';
@Component({
  selector: 'app-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.css'],
})
export class StandardComponent implements OnInit {
  @ViewChild(SelectComponent)
  private standardPicker: SelectComponent;

  @Input() resourceDetailsData;

  public indexControl = 0;
  public index;
  public selectItemData = [];
  constructor(private store: StandardStore) {}
  public eduStandard = [];
  public mutatedItemList = [];

  // public standardOptions: Array<DropdownOptionModel> = [];

  //public standardEduHeader = 'Common Core State Standard...';
  ngOnInit(): void {
    console.log(this.resourceDetailsData);
    //console.log(this.selectItemData);
    for (var i = 0; i < this.resourceDetailsData.length; i++) {
      this.selectItemData.push(
        this.resourceDetailsData[i].standardInfo[0].title +
          '(' +
          this.resourceDetailsData[i].humanCodingScheme +
          ')'
      );
      // console.log(this.selectItemData.length);
    }
    for (var i = 0; i < this.selectItemData.length; i++) {
      var obj = {
        name: this.selectItemData[i],
        selected: false,
      };
      this.mutatedItemList[i] = obj;
    }

    // this.selectItemData.forEach((selectItem) => {

    //     this.standardOptions.push(new DropdownOptionModel(
    //       selectItem,
    //       selectItem
    //     ));

    // });

    // console.log("Mutated ItemList",this.mutatedItemList,this.mutatedItemList.length);
  }
  forward() {
    //this.standardEduHeader = null;
    console.log(this.indexControl, this.resourceDetailsData.length - 1);
    if (this.indexControl < this.resourceDetailsData.length - 1) {
      this.indexControl = this.indexControl + 1;
      //this.standardEduHeader = this.mutatedItemList[this.indexControl].title;
      this.changeStandardSelectPosition();
      console.log(
        this.indexControl,
        this.resourceDetailsData[this.indexControl].notes
      );
    }
  }
  back() {
    //this.standardEduHeader = null;
    if (this.indexControl != 0) {
      this.indexControl = this.indexControl - 1;
      //this.standardEduHeader = this.mutatedItemList[this.indexControl].title;
      console.log(
        this.indexControl,
        this.resourceDetailsData[this.indexControl]
      );
      this.changeStandardSelectPosition();
    }
  }

  dotsClicked(index) {
    //this.standardEduHeader = null;
    this.indexControl = index;
    this.changeStandardSelectPosition();
    //this.standardEduHeader = this.mutatedItemList[index].title;
    console.log(this.indexControl);
  }

  // selectEduStandard(standard: DropdownOptionModel) {
  //   var standardIndex;
  //   for(var i = 0;i < this.standardOptions.length;i++){
  //     if(this.standardOptions[i].value === standard.value){
  //       standardIndex = i;
  //     }
  //   }
  //     console.log(standardIndex);
  //   this.indexControl = standardIndex;
  // }

  selectEduStandard(standard) {
    console.log('SelectedStandard', standard);
    var standardIndex;
    for (var i = 0; i < this.mutatedItemList.length; i++) {
      console.log(
        'onStandardSelect',
        this.mutatedItemList[i].name,
        standard.name
      );
      if (this.mutatedItemList[i].name === standard.name) {
        standardIndex = i;
      }
    }
    console.log(standardIndex);
    this.indexControl = standardIndex;
  }

  changeStandardSelectPosition() {
    this.standardPicker.changeItemSelection(this.indexControl);
  }
}

//@Input() eduStandardList;
//@Output() eduStandardOutput = new EventEmitter
//@Input() resourceDetailsData;
//@Output() resourceDetailsDataOutput = new EventEmitter()
//@Input() indexControl;
//@Output() indexControlOutput = new EventEmitter()*/
//Sample Standard API
//public indexControl = 0;
//public sampleresourceDetailsData = [
//  {header:'NGSS.MS.PS4.2',
//   title:'Next Generation Science Standards Grades 6-8',
//   content:'Develop and use a model to describe that waves are reflected, absorbed, or transmitted through various materials. [Clarification Statement: Emphasis is on both light and mechanical waves. Examples of models could include drawings, simulations, and written descriptions.] [Assessment Boundary: Assessment is limited to qualitative applications pertaining to light and mechanical waves.]'
// },
//{header:'Amdon',
//    title:'Werkz Technology',
//    content:'Develop and use a model to describe that waves are reflected, absorbed, or transmitted through various materials. [Clarification Statement: Emphasis is on both light and mechanical waves. Examples of models could include drawings, simulations, and written descriptions.] [Assessment Boundary: Assessment is limited to qualitative applications pertaining to light and mechanical waves.'
//  },
//  {header:'Werkz',
//    title:'Werkz Publishing',
//   content:'Develop and use a model to describe that waves are reflected, absorbed, or transmitted through various materials. [Clarification Statement: Emphasis is on both light and mechanical waves. Examples of models could include drawings, simulations, and written descriptions.] [Assessment Boundary: Assessment is limited to qualitative applications pertaining to light and mechanical waves.-'
// }
//]
//
