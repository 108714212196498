import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CategoryStore } from './category.store';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css'],
})
export class CategoryComponent {
  @Input() categoryList;
  @Input() defaultSelectedCategoryList = [];
  @Output() categoryOutput = new EventEmitter();
  public gradeList = [{ name: 'a', selected: false }];
  constructor(private store: CategoryStore) {}

  selectedgrade(item) {
    this.categoryOutput.emit(item);
  }
}
