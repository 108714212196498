import { ItemMapper } from 'src/app/lib/item-mapper';


export class LanguageMapper extends ItemMapper<any> {
    protected mapFromItem(item): any {
        console.log('hi',item)

        enum languageList {
            'en' = 'English',
            'ch' = 'Chinese'
        }
        
        
            item=languageList[item]


        console.log(item)

        return item
    }
}
