<div class="transcript-frame">
  <div style="padding: 24px 24px 0 24px">
    <div *ngIf="resourceDetailsData.transcriptURL && resourceDetailsData.transcriptURL != ''">
      <app-text [class]="'body-l text-c300'">
        {{resourceDetailsData.transcriptURL}}
      </app-text>
    </div>
    <div class="empty-transctipt" *ngIf="!resourceDetailsData.transcriptURL || resourceDetailsData.transcriptURL == ''">
      <app-image 
      [src]="'./assets/icons/info.svg'"
      [width]="'47px'"
      [height]="'47px'"></app-image>
      <app-text style="margin-top: 15px;" [class]="'body-l text-s70'">
        There is no transcript data to show.
      </app-text>
      
    </div>
    <!-- <div>
      <app-text [class]="'body-l text-c300'" style="padding-top: 16px">
        [0:03] start addressing the philosophically interesting
      </app-text>
    </div>
    <div>
      <app-text [class]="'body-l text-c300'" style="padding-top: 16px">
        [0:05] things, but in chemistry it just starts right from the
      </app-text>
    </div>
    <div>
      <app-text [class]="'body-l text-c300'" style="padding-top: 16px">
        [0:08] get-go with what's arguably the most philosophically
      </app-text>
    </div>
    <div>
      <app-text [class]="'body-l text-c300'" style="padding-top: 16px">
        [0:11] interesting part of the whole topic, and that's the atom.
      </app-text>
    </div> -->
  </div>
</div>
