import {
  Component,
  Input,
  ViewChildren,
  QueryList,
  ElementRef,
  HostListener,
} from '@angular/core';
import { InputStore } from './input.store';
import { FormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
})
export class InputComponent {
  @Input() type: 'text' | 'number' | 'password' | 'email' = 'text';
  @Input() multiline = false;
  @Input() rows = 5;
  @Input() hint;
  @Input() class = '';
  @Input() readonly = '';
  @Input() textAlign = '';

  @Input() control: FormControl | AbstractControl;

  @ViewChildren('single') single: QueryList<ElementRef>;
  @ViewChildren('multi') multi: QueryList<ElementRef>;

  @Input() width;
  @Input() height;
  // @Input() value='';

  public focus() {
    if (this.single.first) this.single.first.nativeElement.focus();
    if (this.multi.first) this.multi.first.nativeElement.focus();
  }

  constructor() {}

  ngOnInit() {
    if (this.class == 'input-search bg-s20 input-no-border')
      this.width = '320px';
  }

  search() {
    console.log('click search');
  }
}
