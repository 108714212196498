export class ResourceDetailsModel {
    accessMode: any = ["auditory", "color", "textOnImage", "visual"]
    accessibilityHazards: any = []
    aerGUID: string = "ed310ef5318f41fc81895057"
    aerReferenceID: string = "LEY-02-01"
    author: any = ["Amdon"]
    createdAt: string = "2020-07-09T02:55:02.649Z"
    description: string = "In this interactive learning object, students can explore looking through hollow tubes of different curvatures to study the pattern of light rays from an illuminated light source."
    educationalAudience: any = ["student", "teacher", "administrator"]
    language: any = [ "en"]
    languageRegiondesignator: any = ["en-US"]
    learningObjectives: any = []
    learningResourceType: any = ["Text/Book", "Text/Document"]
    locales: Locales = new Locales(null, null)
    name: string = "Earth 1"
    pedagogicalModels: any = ["Explain", "Explore"]
    publisher: string = "Werkz Publishing Inc"
    related: any
    standardNodesInfo: any
    subject: any = ["Science"]
    targetFileHeight: string = "650"
    targetFileThumbnailURL: string = "https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/ed310ef5318f41fc81895057/resources/Default.png"
    targetFileURL: string = "https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/ed310ef5318f41fc81895057/resources/ley-02-01/Assets/index.html"
    targetFileWidth: string = "1024"
    technicalFormat: string = "HTML"
    textComplexity: any = []
    timeRequired: string = "PT3M"
    transcriptURL: string = ""
    age: string = "11-14"
    updatedAt: string = "2020-07-09T02:55:02.649Z"
    version: number = 0
    id: string = "5f06870655614400130f57e0"

}

export class Locale {

    constructor(
        public code: string,
        public label: string
    ){

    }

}

// {
//     "en-GB": "English(UK)",
//     "en-US": "English(US)",
//     "en-SG": "English(SG)",
//     "zh-CN": "中文 (简体)"
//   }

export class Locales {

    constructor(
        public defaultURL: string,
        public targetFileURL: any
    ) {

    }

    private defaultLocaleCode = 'en-US'
    private LANGUAGE_PAIRS = {
        'en-GB': 'English(UK)',
        'en-US': 'English(US)',
        'en-SG': 'English(SG)',
        'zh-CN': '中文 (简体)'
    }
    
    getLanguageOptions(): Array<Locale> {

        let options: Array<Locale> = []
        let defaultLocale = new Locale(this.defaultLocaleCode, this.LANGUAGE_PAIRS[this.defaultLocaleCode])

        if(this.targetFileURL) {

            options.push(defaultLocale);

            Object.keys(this.targetFileURL).forEach(key => {

                options.push(new Locale(key, this.LANGUAGE_PAIRS[key]));

            })

        }

        return options;
    }

    getUrlByLocale(locale: string) {

        if(locale === this.defaultLocaleCode)
            return this.defaultURL

        return this.targetFileURL[locale];

    }

    checkIsMultiLingual(): boolean {

        return this.targetFileURL && Object.keys(this.targetFileURL).length != 0
    }

}


// locales
// : 
// {name: {}, description: {}, targetFileURL: {,…}, targetFileURLIOS: {,…}}
// description
// : 
// {}
// name
// : 
// {}
// targetFileURL
// : 
// {,…}
// en-GB
// : 
// "https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/52eae27a6e18403985870bb0/10/resources/ley-03-01-i1-uk/Assets/index.html"
// zh-CN
// : 
// "https://s3.amazonaws.com/pagewerkz-authoring/learning-object-resources/dev/uploads/resources/52eae27a6e18403985870bb0/10/resources/ley-03-01-i1-chi/Assets/index.html"
