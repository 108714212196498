import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { SearchStore } from './search.store';
import {
  FormControl,
  AbstractControl,
  Validators,
  FormBuilder,
} from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent {
  @Input() control: FormControl | AbstractControl;
  @Input() hint = '';
  @Input() width = '100%';
  @Input() height;
  @Input() boxHeight = null;
  @Input() boxWidth = null;

  @Input() icon = true;
  @Input() keyword: any;

  @Output() searchOutput = new EventEmitter<any>();

  public data = '';
  btnBorder = 'button-border button-search bg-s20';
  inputSearch = 'input-search bg-s20';

  public form = this.fb.group({
    searchData: this.fb.control('', [Validators.required]),
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form.valueChanges.subscribe((value) => {
      this.data = value.searchData;
      console.log('search value changes');
      if (this.data == '') this.buttonOutput(value);
    });
    console.log('this.keyword ', this.keyword);
    this.form.controls.searchData.setValue(this.keyword);
  }

  keyChange(e) {
    console.log(e);
    // if(e.keyCode == 13 || e.keyCode == 'Enter'){
    this.searchOutput.emit(this.data);
    // }
  }

  @HostListener('document:click', ['$event'])
  outside(event) {
    // console.log('event.target.id ', event.target.id);
    // if (event.target.id && event.target.id == 'search') {
    //   if (this.data !== '' && this.data !== null && this.data !== undefined) {
    //     console.log('if', this.data);
    //     this.data = '';
    //     // this.searchOutput.emit(this.data);
    //   } else {
    //     console.log('else');
    //   }
    // }
  }

  buttonOutput(e) {
    this.searchOutput.emit(this.data);
  }
}
