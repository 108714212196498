<app-text [class]="'h4-strong'">
  Standards
</app-text>
<app-select
  [selectHeader]="'Pick A Standard'"
  [boxWidth]="'360px'"
  [type]="'type1'"
  [default]="true"
  [boxHeight]="'40px'"
  [headerClass]="'headerClasstwo'"
  [itemList]="mutatedItemList"
  (selectOutput)="selectEduStandard($event)"
></app-select>
<!-- <app-dropdown
    [options]="standardOptions"
    [hint]="'Pick A Standard'"
    [width]="360"
    (onOptionSelected)="selectEduStandard($event)">
</app-dropdown> -->
<div class="d-flex justify-content-between">
  <div>
    <span
      class="dot"
      *ngFor="let dotAmount of resourceDetailsData; let index = index"
      [ngStyle]="{ background: index == indexControl ? '#0065F2' : '' }"
      (click)="dotsClicked(index)"
    ></span>
  </div>
  <div>
    <app-image
      [src]="'./assets/icons/arrow_left.svg'"
      style="margin-right: 36px;"
      (click)="back()"
      [ngStyle]="{ cursor: indexControl == 0 ? 'not-allowed' : 'pointer' }"
    ></app-image>
    <app-image
      [src]="'./assets/icons/arrow_right.svg'"
      [ngStyle]="{
        cursor:
          indexControl == resourceDetailsData.length - 1
            ? 'not-allowed'
            : 'pointer'
      }"
      (click)="forward()"
    ></app-image>
  </div>
</div>
<div *ngFor="let data of resourceDetailsData; let index = index">
  <div *ngIf="index == indexControl">
    <app-text [class]="'small'">{{ data.humanCodingScheme }}</app-text>
  </div>
  <div *ngIf="index == indexControl">
    <app-text [class]="'h5-strong'">{{ data.fullStatement }}</app-text>
  </div>
  <div *ngIf="index == indexControl">
    <app-text [class]="'body-m'">{{ data.notes }}</app-text>
  </div>
</div>
