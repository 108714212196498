<table class="type-value-table">
    <tr>
        <td class="small text-c300 type">Subject</td>
        <td class="small text-g200 value">
            <app-text *ngFor="let item of resource.subject; let last = last">
                {{!last ? item+',' : item}}
            </app-text>
        </td>
    </tr>
    <tr>
        <td class="small text-c300 type">Material types</td>
        <td class="small text-g200 value">
            <app-text *ngFor="let item of resource.learningResourceType; let last = last;">
                {{!last ? item+',' : item}}
            </app-text>

        </td>
    </tr>
    <tr>
        <td class="small text-c300 type">Age</td>
        <td class="small text-g200 value">{{resource.age}}</td>
    </tr>
    <tr>
        <td class="small text-c300 type">Text complexity</td>
        <td class="small text-g200 value">{{resource.textComplexity}}</td>
    </tr>
    <tr>
        <td class="small text-c300 type">Language</td>
        <td class="small text-g200 value" *ngFor="let item of resource.language">{{item}}</td>
    </tr>
</table>