import {Component, Input, OnInit} from '@angular/core';
import {ButtonStore} from './button.store'

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit{
    @Input() disabled=false;
    @Input() additionalCSS='';
    @Input() width;
    @Input() height;
    @Input() class="button-red";
    constructor(private store: ButtonStore) {}
  ngOnInit(): void {
    console.log(this.class);
  }

    onButtonClick(e){
        console.log()
    }
}
