import { BusinessLogicRequirements } from '../presentation/business-logic.requirements';
import { NgModule } from '@angular/core';
import { DataRequirementsInjectionToken } from './data.requirements';
import { DataFacade } from '../data/data.facade';
import { Observable, of } from 'rxjs';
import { GetAllSubjectsUseCase } from './use-cases/get-all-subjects.use-case';
import { GetAllLanguagesUseCase } from './use-cases/get-all-languages.use-case';
import { GetAllMaterialTypesUseCase } from './use-cases/get-all-material-types.use-case';
import { GetAllPedagogicalsUseCase } from './use-cases/get-all-pedagogicals.use-case';
import { SearchResourceUseCase } from './use-cases/search-resource.use-case';
import { ResourceMapper } from './mappers/resource.mapper';
import { LanguageMapper } from './mappers/language.mapper';
//import { DataRequirementsFake } from './data.requirements.fake';
import { GetRelatedDetailsUseCase } from './use-cases/get-related-details.use-case';
import { GetResourceDetailsUseCase } from './use-cases/get-resource-details.use-case';
import { ResourceDetailsMapper } from './mappers/resource-details.mapper';
import { PostUrlUseCase } from './use-cases/post-url.use-case';

@NgModule({
  imports: [DataFacade],

  providers: [
    {
      provide: DataRequirementsInjectionToken,
      useClass: DataFacade,
      //useClass : DataRequirementsFake
    },
    ResourceMapper,
    LanguageMapper,
    ResourceDetailsMapper,
  ],
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
  constructor(
    private getAllSubjectsUseCase: GetAllSubjectsUseCase,
    private getAllLanguagesUseCase: GetAllLanguagesUseCase,
    private getAllMaterialTypesUseCase: GetAllMaterialTypesUseCase,
    private getAllPedagogicalsUseCase: GetAllPedagogicalsUseCase,
    private searchResourceUseCase: SearchResourceUseCase,
    private getResourceDetailsUseCase: GetResourceDetailsUseCase,
    //private getRelatedDatailsUseCase : GetRelatedDetailsUseCase
    private postUrlUseCase: PostUrlUseCase
  ) {}

  getResourceDetails(resourceID: any): Observable<any> {
    console.log(resourceID);
    return this.getResourceDetailsUseCase.run(resourceID);
  }
  /*getRelatedDetails(id): Observable<any> {
        console.log('Here');
        return this.getRelatedDatailsUseCase.run(id);
    }*/
  getAllLanguages(): Observable<any> {
    return this.getAllLanguagesUseCase.run();
  }
  getAllMaterialTypes(): Observable<any> {
    return this.getAllMaterialTypesUseCase.run();
  }
  getAllSubjects(): Observable<any> {
    return this.getAllSubjectsUseCase.run();
  }
  getAllPedagogicalModels(): Observable<any> {
    return this.getAllPedagogicalsUseCase.run();
  }
  searchResource(
    identifier,
    keyword,
    subject,
    materialTypes,
    language,
    pedagogicalModels,
    limit,
    skip,
    sortDirection,
    platformId
  ): Observable<any> {
    return this.searchResourceUseCase.run(
      identifier,
      keyword,
      subject,
      materialTypes,
      language,
      pedagogicalModels,
      limit,
      skip,
      sortDirection,
      platformId
    );
  }
  postUrl(aerUrl): Observable<any> {
    return this.postUrlUseCase.run(aerUrl);
  }
}
